@include respond-to(extra-small) {
  .text__title, .question {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
  }
  .text__content, .answers{
    text-align: justify;
    font-size: $font-mobile-body;
    line-height: $line-height-mobile-body;
  }
}