.reseller__title {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('#{$images-static}reseller-header.png') no-repeat center;
  padding: $padding-giant $padding-tiny;
  @include respond-to(extra-small) {
    padding: $padding-medium $padding-tiny;
    background-position: 70% 0;
    background-size: cover;
  }
  color: $text-light;
  .text__title, .title {
    margin-bottom: $padding-extra-small;
  }
  .title {
    text-align: center;
    font-size: $font-display*1.5;
    font-weight: lighter;
    line-height: $line-height-display*1.5;
    @include respond-to(six-hundred) {
      font-size: $font-display*1.2;
      line-height: $line-height-display*1.2;
    }

  }
  .action__play{
    display: flex;
    justify-content: center;
    a:after {
      content: url('#{$images-icons}play-dark.svg');
    }
  }
  .button--reseller{
    background: white;
    color: $text-dark;
    width: 180px;
  }
}

.article--reseller{
  background: white;
  @include respond-to(extra-small) {
    padding: $padding-medium $padding-tiny;
  }
  .content--center{
    max-width: $max-width;
    margin: 0;
    .text__title {
      font-weight: normal;
    }
    .text__subtitle{
      max-width: 990px;
    }
  }
}

.reseller__container {
  display: flex;
  justify-content: center;
}

.article--reseller, .reseller__get-involved, .reseller__offer, .reseller__smart, .reseller__footer {
  color: $text-grey;
  .text__title{
    font-weight: normal;
  }
}

.reseller__get-involved {
  height: 460px;
  background: $default;
  @include respond-to(small){
    height: auto;
  }
  .get-involved--wrap {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    @include respond-to(small) {
      flex-direction: column;
      height: auto;
    }
    .get-involved__image {
      background: url('#{$images-static}reseller-get-involved.png') no-repeat;
      background-position-x: 78%;
      width: 50%;
      left: 0;
      height: 460px;
      display: block;
      @include respond-to(small) {
        width: 100%;
        height: 200px;
        flex: 1 0 200px;
        position: relative;
        background-position: top 38% left 84%;
      }
    }
    .get-involved__body {
      flex: 2;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      justify-content: center;
      margin: 0 $padding-large;
      height: 100%;
      text-align: left;
      min-width: 320px;
      @include respond-to(small) {
        width: 100%;
        flex: 1 1 auto;
        min-height: 424px;
        max-width: 800px;
        min-width: 0;
        margin: 0;
        padding: $padding-normal;
      }
      @include respond-to(extra-small){
        padding: $padding-medium $padding-mobile;
        margin: 0;
      }
      .text__title {
        margin-bottom: $padding-extra-small;
        text-align: left;
        @include respond-to(small){
          text-align: center;
        }
      }
    }
  }

  .points {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin:0;
    @include respond-to(small){
      justify-content: center;
    }
  }
  .item {
    display: flex;
    align-items: center;
    @include respond-to(extra-small) {
      width: 100%;
      padding: $padding-mobile 0;
    }
    div {
      width: 280px;
      text-align: left;
      padding-left: $padding-tiny;
      @include respond-to(extra-small){
        font-size: $font-mobile-body;
        line-height: $line-height-mobile-body;
        flex-basis: 75%;
      }
    };
  }
}

.reseller__smart {
  background: $text-superlight;
  min-height: 460px;
  .smart--wrap{
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    flex-direction: row-reverse;
    @include respond-to(small) {
      flex-direction: column;
      height: auto;
    }
    .smart__image {
      background: url('#{$images-static}reseller-smart.png') no-repeat;
      background-position-x: 71%;
      width: 50%;
      min-height: 460px;
      right: 0;
      @include respond-to(small) {
        width: 100%;
        height: 200px;
        min-height: 0;
        position: relative;
        background-position: top 100% left 61%;
      }
    }
    .smart__body {
      max-width: 500px;
      min-width: 320px;
      text-align: right;


      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 $padding-large;
      @include respond-to(small){
        flex: 1 1 auto;
        min-height: 302px;
        margin: 0;
        width: 100%;
        min-width: 0;
        max-width: 800px;
        padding: $padding-normal $padding-extra-small;
      }
      @include respond-to(extra-small){
        padding: $padding-medium $padding-mobile;
        margin: 0;
      }
      .text__title {
        text-align: right;
        font-weight: normal;
        margin-bottom: $padding-extra-small;
        @include respond-to(small){
          text-align: center;
        }
      }
      .text__body {
        font-size: $font-body;
        line-height: $line-height-body;
        @include respond-to(small){
          text-align: center;
        }
        @include respond-to(extra-small){
          text-align: justify;
          font-size: $font-mobile-body;
          line-height: $line-height-mobile-body;
        }
      }
    }
  }
}

.reseller__offer{
  padding: $padding-large $padding-extra-small;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include respond-to(extra-small){
    padding: $padding-medium $padding-extra-small;
  }
  .title {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .text__title{
    width: 100%;
  }
  .text__subtitle{
    width: 100%;
    max-width: 600px;
    margin: $padding-small 0;
  }
  .list{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 305px;
    @include respond-to(extra-small){
      width: 100%;
    }
  }
  .item{
    width: 305px;
    height: 56px;
    font-size: $font-headline;
    line-height: $line-height-headline;
    padding: $padding-tiny;
    display: flex;
    align-items: center;
    @include respond-to(six-hundred){
      width: 350px;
    }
    @include respond-to(extra-small){
      width: 100%;
      font-size: $font-mobile-body;
      line-height: $line-height-mobile-body;
      padding: $padding-tiny*0.5;
    }
    .fa-stack {
      font-size: 20px;
      margin-right: $padding-tiny*0.5;
      flex-shrink: 0;
    }
    div {
      font-size: $font-body;
      line-height: $line-height-body;
    }

  }
}

.reseller__contact{
  background: $dark-grey;
  overflow: hidden;
  height: 758px;
  @include respond-to(extra-small){
    height: 810px;
  }

  &.ng-hide-add {
    transition: 3s;
    transform: translateZ(0);
  }
  &.ng-hide-add.ng-hide-add-active { height: 0; }

  .wrap {
    display: flex;
    justify-content: center;
    padding: $padding-giant $padding-tiny;
    color: $text-light;
    flex-flow: wrap;
    height: 100%;
    @include respond-to(extra-small) {
      padding: $padding-medium $padding-tiny;
    }
  }

  .text__title, .text__subtitle{
    width: 100%;
  }
  .text__title{
    font-weight: normal;
  }
  .text__subtitle{
    font-weight: lighter;
    font-size: $font-headline;
    line-height: $line-height-headline;
    @include respond-to(six-hundred){
      font-size: $font-subhead;
    }
  }
  .form{
    width: 100%;
    max-width: 454px;
    @include respond-to(six-hundred){
      max-width: 400px;
    }
  }
  .material-form{
    display: flex;
    flex-wrap: wrap;
  }
  .form__input{
    flex: 0 0 50%;
    padding: 0 $padding-tiny;
    max-width: 50%;
    @include respond-to(six-hundred){
      width: 200px;
    }
    @include respond-to(extra-small){
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  .form__input--message {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    .form-group{
      textarea {
        height: 172px;
      }
      &:before {
        height: 150px;
      }
    }
  }
  .form-group{
    margin: 0;
    label, .error-block{
      padding-left: $padding-tiny*0.5;
    }
    .error-block{
      top: 48px;
    }
    label{
      top: 7px;
    }
    input[type='text'], input[type='email'], textarea[type='text']{
      border: none;
      color: $text-light;
      padding-left: $padding-tiny*0.5;
    }
    textarea[type='text']{
      resize: none;
      overflow: hidden;
    }
    &:after{
      display: none;
    }
    &:before {
      content: '';
      position: absolute;
      transition: all .3s ease;
      background: $light-grey;
      border-radius: $border-radius;
      height: 28px;
      width: 100%;
      top: 22px;
    }

    &.has-focus {
      &::before {
        background: $primary;
      }
    }

    &.has-error {
      &::before {
        background: $red;
      }
    }
    &.has-success.has-focus, &.has-success:not(.is-empty) {
      &.has-focus::before, &::before {
        background: $primary;
      }
    }

    &.is-empty:not(.has-focus) label {
      top: 30px;
    }
  }
  .button__container{
    display: flex;
    justify-content: center;
    padding: 0 $padding-tiny;
    margin-top: $padding-tiny;
  }
  .send {
    width: 100%;
    padding: $padding-tiny $padding-small;
    background-color: $primary;
    color: #FFFFFF !important;
    text-align: center;
    border-radius: $border-radius;
    &.disabled {
      cursor: not-allowed;
      background: $lighter-grey;
    }
  }
  .formSaved{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
  }
  .formError {
    padding: $padding-tiny;
    border-radius: $border-radius;
    background-color: $red;
    position: relative;
    color: $text-light;
    top:-10px;
    margin: 0 $padding-tiny;

    .text {
      text-align: left;
      margin-right: 30px;
    }

    .alert {
      position: absolute;
      right: 25px;
      top: 20%;
      font-size: 28px;
      opacity: 0.6;
    }
  }
}

.reseller__footer{
  padding-bottom: $padding-tiny;
  .text__subtitle{
    width: 100%;
    font-size: $font-headline;
    line-height: $line-height-headline;
  }


  .contact__block {
    display: flex;
    max-width: 700px;
    width: 100%;
    justify-content: space-around;
  }
  .contact {
    max-width: 41%;
    margin: 14px;
    @include respond-to(medium-large) {
      flex-basis: 30%;
      margin: 7px;
    }
    display: flex;
    align-items: center;
    flex-flow: column;
    .avatar-1 {
      background: url('#{$images-static}janis.png') no-repeat center;
    }
    .avatar-2 {
      background: url('#{$images-static}toms.png') no-repeat center;
    }
    .avatar {
      width: 200px;
      height: 200px;
      background-size: contain;
      margin: 14px;
      position: relative;
      @include respond-to(six-hundred) {
        height: 177px;
        width: 177px;
      }
      @include respond-to(extra-small) {
        height: 110px;
        width: 110px;
      }
    }
    .media {
      .title {
        font-size: $font-headline;
        text-transform: uppercase;
        line-height: $line-height-headline;
        margin-bottom: 8px;
        width: 178px;
        text-align: center;
        @include respond-to(extra-small) {
          width: 130px;
        }
      }
    }
  }
}