.article--heading {
  background: $text-superlight url('#{$images-static}header.jpg') no-repeat top;
  background-size: 1920px 520px;
  display: flex;
  position: relative;
  width: 100%;
  height: 520px;
  justify-content: flex-end;
  align-items: center;
  @include respond-to(large) {
    align-items: flex-start;
    justify-content: center;
    background-size: 1920px * 0.8 520px  * 0.8;
    height: 520px * 0.8;
    background-position: top left 58%;
  }
  @include respond-to(medium) {
    height: 560px;
    background: $text-superlight url('#{$images-static}@tablet_bg.jpg') no-repeat;
    background-size: 100% auto;
    background-position: top 25% left;
  }
  @include respond-to(small) {
    background-position: 0 0;
    height: 520px;
  }
  @include respond-to(extra-small) {
    background:$text-superlight url('#{$images-static}@mobile_bg.jpg') no-repeat;
    background-position: 0 -160px;
    background-size: 100% auto;
    height: 500px;
  }
  @media(max-width: 370px) {
    background-position: 0 -60px;
    height: 450px;
  }

  .animation {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text__title, .text__subtitle {
    text-align: left;
    @include respond-to(large) {
      text-align: center;
    }
  }
  .text__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 1.5;
  }
  .text__subtitle {
    max-width: 65%;
    @include respond-to(large) {
      max-width: 100%;
    }
  }
  .content--off-center {
    position: absolute;
    bottom: 43%;
    max-width: 420px;
    right: 50%;
    margin-right: -533px;
    @include respond-to(large) {
      position: relative;
      padding: $padding-tiny;
      max-width: 100%;
      bottom: 0;
      margin-right: 0;
      right: inherit;
    }
    @media (max-width: 330px) {
      padding: $padding-mobile 9px;
    }

  }
}

.article--alternate {
  background: $text-superlight;
  display: flex;
  justify-content: center;
  padding: $padding-large $padding-tiny;
  @include respond-to(extra-small){
    padding-top: $padding-mobile;
    padding-bottom: $padding-medium;
  }
  .content--center {
    max-width: 760px;
    @include respond-to(large) {
      margin-top: 60px;
    }
    @include respond-to(medium){
      margin-top: 0;
    }
    .text__title {
      margin-bottom: $padding-small;
      font-weight: bold;
    }
    .text__subtitle{
      margin-bottom: 0;
      @include respond-to(extra-small){
        font-size: $font-mobile-body;
        line-height: $line-height-mobile-body;
        text-align: justify;
      }
    }
  }
}

.article--economy {
  background: $green;
  color: white;
  display: flex;
  justify-content: center;
  height: auto;
  .economy--wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: $max-width;
    padding: $padding-large;
    background: url('#{$images-static}sensors-blue-fona.png') no-repeat;
    background-size: 400px 363px;
    background-position: 87% 94px;
    @include respond-to(medium-large){
      background-position-x: 95%;
    }
    @include respond-to(medium) {
      background-position: 103% 70%;
      flex-wrap: wrap;
      align-items: center;
    }
    @include respond-to(small) {
      background-position: 50% 22%;
      padding: $padding-large 0;
    }
    @include respond-to(extra-small){
      background-size: 0.8*400px 0.8*363px;
      background-position-y: 18%;
      padding: $padding-medium 0;
    }
    .text__title {
      margin-bottom: 28px;
    }
    .text__content {
      margin-bottom: 40px;
    }
    .action--more {
      display: flex;
      justify-content: flex-end;
    }
    .article--economy--split {
      flex: 1 1 50%;
      text-align: right;
      .text__title {
        text-align: right;
      }
      @include respond-to(small) {
        flex: 1 1 auto;
        order: 2;
        text-align: left;
        padding: $padding-mobile $padding-mobile $padding-medium;
        min-height: 340px;
        margin-top: -74px;
        min-width: 100%;
        .text__title {
          text-align: left;
        }
        .action--more {
          justify-content: flex-start;
        }
      }
      @include respond-to(extra-small) {
        .text__title {
          text-align: center;
        }
      }
    }
    .article--economy--image {
      flex: 2 0 50%;
      max-width: 50%;
      padding: $padding-tiny;
      height: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-to(small) {
        flex-basis: auto;
        order: 1;
        max-width: 100%;
        height: 400px;
      }
      @include respond-to(extra-small){
        height: 360px;
      }
      .economy--badge {
        background: url('#{$images-static}badge.png') no-repeat center;
        background-size: 108px 112px;
        height: 112px;
        width: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        top: -56px;
        left: -90px;

        @include respond-to(medium) {
          left: 54%;
          top: -154px;
        }
        @include respond-to(small) {
          left: -136px;

        }
        @include respond-to(extra-small) {
          left: -95px;
          top: -138px;
        }
        .co {
          font-weight: bold;
          font-size: 38px;
          sub {
            font-size: 16px;
          }
        }
        .sub {
          margin-top: 4px;
          font-size: 10px;
        }
      }
    }
  }
}

.article--apps {
  background: $text-superlight;
  display: flex;
  height: 100%;
  justify-content: center;
  .apps--wrap{
    width: 100%;
    max-width: $max-width;
    display: flex;
    justify-content: center;
    padding: $padding-large $padding-extra-small;
    @include respond-to(small) {
      flex-direction: column;
      align-items: center;
      padding: $padding-medium $padding-mobile;
    }
    .apps--text {
      width: 560px;
      display: flex;
      flex-direction: column;
      padding-top: $padding-large;
      text-align: left;
      @include respond-to(medium) {
        width: 50%;
      }
      @include respond-to(small) {
        padding-top: 30px;
        width: 100%;
      }
      @include respond-to(six-hundred){
        padding-top: 0;
      }
      .text__title {
        text-align: left;
        margin-bottom: 14px;
        @include respond-to(extra-small) {
          text-align: center;
        }
      }
      .text__content{
        @include respond-to(extra-small) {
          font-size: $font-mobile-body;
          line-height: $line-height-mobile-body;
        }
      }
      .action--platform {
        margin-top: $padding-extra-small;
        @include respond-to(extra-small){
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}


.slider {
  height: auto;
  display: flex;
  justify-content: center;
  user-select: none;
  .slider__wrap {
    display: flex;
    justify-content: center;
    width: 100%;

    .toLeft, .toRight {
      .slide {
        transform: translateZ(0);
      }
      .ng-hide-add, .ng-hide-remove {
        transition: all linear 0.3s;
      }
    }

    .toLeft {
      .ng-hide-add { transform: translateX(0) }
      .ng-hide-add.ng-hide-add-active { transform: translateX(-100%); }
      .ng-hide-remove { transform: translateX(100%) }
      .ng-hide-remove.ng-hide-remove-active { transform: translateX(0);}
    }

    .toRight {
      .ng-hide-add { transform: translateX(0) }
      .ng-hide-add.ng-hide-add-active { transform: translateX(100%); }
      .ng-hide-remove { transform: translateX(-100%) }
      .ng-hide-remove.ng-hide-remove-active { transform: translateX(0);}
    }

    .container {
      width: 100%;
      height: 570px;
      overflow: hidden;
      position: relative;
      background: #e6e6e6;
      @include respond-to(six-hundred) {
        height: 510px;
      }
    }

    .slide {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .arrow {
      position: absolute;
      z-index: 99;
      display: block;
      top: 50%;
      margin-top: -35px;
      width: 36px;
      height: 71px;
      outline: none;
      cursor: pointer;
      i{
        font-size: 77px;
        color: $lighter-grey;
      }
    }

    .arrow.prev {
      opacity: 0.2;
      left: 20px;
      transition: 0.2s linear all;
    }

    .arrow.next {
      opacity: 0.2;
      right: 20px;
      transition: 0.2s linear all;
      text-align: right;
    }

    .arrow.prev:hover, .arrow.next:hover {
      opacity: 1;
    }

    .nav {
      text-align: center;
      display: block;
      position: absolute;
      z-index: 99;
      left: 0;
      bottom: -4px;
      right: 0;
      height: 48px;
    }

    .nav .wrapper {
      margin: 0 auto;
      width: 100%;
      padding: 1em 0 .8em;
    }

    .nav ul {
      margin: 0;
      width: 100%;
    }

    .nav .dot, .nav .dot a {
      display: inline-block;
      zoom: 1;
    }

    .dots .dot {
      position: relative;
      margin: 0 8px;
      width: 12px;
      height: 12px;
    }

    .dots .dot a {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: rgba(103,106,111,0.5);
      border: 1px solid transparent;
      outline: none;
      box-shadow: none;
      border-radius: 50%;
      transition: background-color 0.25s, border-color 0.25s;
    }

    .dots .dot a.active {
      border-color: rgba(103,106,111,0.5);
      background-color: transparent;
    }

  }
}
.article--radiator {
  .background-image {
    @keyframes fadedOut {
      from   { background: $dark-blue; }
      to { background: $yellow;}
    }
    @keyframes fadedIn {
      from   { background: $yellow; }
      to { background: $dark-blue;}
    }
    color: white;
    padding: $padding-large $padding-extra-small;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 570px;

    animation: fadedOut 1s ease-in;
      @include respond-to(extra-small) {
        animation: none;
      }
    background: $yellow;
    &.faded {
      animation: fadedIn 1s ease-out;
      @include respond-to(extra-small) {
        animation: none;
      }
      background: $dark-blue;
    }
    @include respond-to(six-hundred) {
      min-height: 510px;
    }
    @include respond-to(small) {
      padding: $padding-extra-small;
    }
    @include respond-to(extra-small) {
      padding: 12px;
    }
  }

  .article__content {
    position: relative;
  }
  .text__radiator {
    position: absolute;
    top: 120px;
    left: 250px;
    @include respond-to(small) {
      top: 320px;
      left: 20%;
    }
    @include respond-to(six-hundred) {
      top: 260px;
    }
    @include respond-to(extra-small) {
      left: 25%;
    }
    .whereabouts {
      font-size: 42px;
      font-weight: 400;
      position: absolute;
      left: 180px;
      width: 200px;
      bottom: 14px;

      &.ng-hide-remove {
        transition: all linear 0.8s;
        transform: translateZ(0);
        opacity: 0;
      }

      &.ng-hide-add {
        opacity: 0;
      }
      &.ng-hide-add, &.ng-hide-add.ng-hide-add-active, &.ng-hide-remove {
        transform: translateX(100%)
      }
      &.ng-hide-remove.ng-hide-remove-active {
        transform: translateX(0);
        opacity: 1;
      }

      @include respond-to(medium) {
        font-size: 39px;
        width: 190px;
      }

      @include respond-to(small) {
        font-size: 34px;
      }

      @include respond-to(extra-small) {
        bottom: -50px;
        left: 0;
        font-size: 30px;
      }
    }


  }
  .clock {
    font-size: 72px;
    font-weight: 300;
    margin-bottom: 40px;
    @include respond-to(small) {
     margin-bottom: 10px;
    }
    @include respond-to(extra-small) {
      font-size: 56px;
    }
  }
  .temperature {
    font-size: 112px;
    font-weight: bold;
    width: 200px;
    position: relative;
    @include respond-to(extra-small) {
      font-size: 80px;
    }
  }


  .image__radiator {
    background: url('#{$images-static}rad_galv.png') no-repeat;
    background-size: 1064px 472px;
    background-position: bottom right;
    height: 472px;
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    @include respond-to(small) {
      bottom: -44px;
      width: 100%;
      background: url('#{$images-static}rad_mob.png') no-repeat;
      background-size: 250px 445px;
    }
  }
}

.article--setup {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  align-items: center;
  padding: $padding-large 0;
  @include respond-to(small) {
    padding: $padding-small 0;
  }
  .setup__wrap {
    max-width: $max-width;
    width: 100%;
    height: 100%;
    .setup__heading {
      margin-bottom: $padding-medium;
      @include respond-to(large) {
        margin-bottom: 40px;
      }
      @include respond-to(extra-small) {
        margin: 0 12px 12px 12px;
      }
    }
    .setup__boxes {
      display: flex;
      justify-content: center;
      @include respond-to(small) {
        height: 80%;
        flex-direction: column;
        align-items: center;
      }
      @include respond-to(six-hundred) {
        height: 70%;
      }
      .setup__box {
        position: relative;
        flex: 1 1 0;
        min-width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include respond-to(large) {
          margin-bottom: $padding-extra-small;
        }
        @include respond-to(medium) {
          min-width: 256px;
        }
        @include respond-to(small) {
          min-width: 256px;
          flex-direction: row;
        }
        &:nth-child(1):after, &:nth-child(2):after {
          @include respond-to(medium) {
            top: 77px;
          }
          @include respond-to(small) {
            display: none;
          }
          content: url('#{$images-icons}easy-bulta.png');
          display: block;
          position: absolute;
          top: 100px;
          right: -5px;
        }
        .text {
          align-self: center;
          padding: $padding-tiny;
          margin-top: 8px;
          max-width: 240px;
          color: $text-grey-light;
          line-height: 1.5;
          padding-bottom: 0;
          .button {
            margin-top: $padding-extra-small;
          }
          @include respond-to(small) {
            text-align: left;
            margin: 0;
            padding: 0 0 0 20px;
          }
          @include respond-to(extra-small) {
            max-width: 206px;
          }
        }
        .picture {
          width: 210px;
          height: 210px;
          padding: $padding-tiny;
          position: relative;
          &.step-1 {
            background: url('#{$images-static}easy-1.png') center no-repeat;
            background-size: cover;

          }
          &.step-2 {
            background: url('#{$images-static}easy-2.png') center no-repeat;
            background-size: cover;
            &:after {

            }
          }
          &.step-3 {
            background: url('#{$images-static}easy-3.png') center no-repeat;
            background-size: cover;
          }
          @include respond-to(medium) {
            width: 170px;
            height: 170px;
          }
          @include respond-to(small) {
            width: 84px;
            height: 84px;
          }
        }
      }
    }
  }
}

.article--sets {
  background: $text-superlight;
  padding: $padding-large $padding-extra-small;
  display: flex;
  justify-content: center;
  @include respond-to(extra-small){
    padding: $padding-medium 0;
  }
}

.article--features {
  display: flex;
  justify-content: center;
}

.article--compatibility {
  display: flex;
  justify-content: center;
  .compatibility__wrap {
    width: 100%;
    max-width: $max-width;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: $padding-large 0;
    @include respond-to(extra-small){
      padding: $padding-medium 0;
    }
    .compatibility__heading{
      padding: 0 $padding-extra-small;
      .text__subtitle{
        margin: $padding-small 0;
      }
    }
  }

  .compatibility__block {
    display: flex;
    max-width: $max-width;
    justify-content: center;
    @include respond-to(medium) {
      flex-flow: wrap;
    }
    @include respond-to(extra-small){
      justify-content: space-around;
    }
  }

  .compatibility {
    max-width: 41%;
    margin: 14px;
    @include respond-to(medium-large) {
      flex-basis: 30%;
      margin: 7px;
    }
    display: flex;
    align-items: center;
    flex-flow: column;
    .avatar-1 {
      background: url('#{$images-heating}gas.png') no-repeat center;
    }
    .avatar-2 {
      background: url('#{$images-heating}central.png') no-repeat center;
    }
    .avatar-3 {
      background: url('#{$images-heating}heat.png') no-repeat center;
    }
    .avatar-4 {
      background: url('#{$images-heating}electricity.png') no-repeat center;
    }
    .avatar-5 {
      background: url('#{$images-heating}heating_floors.png') no-repeat center;
    }
    .avatar {
      width: 100%;
      height: 177px;
      background-size: contain;
      margin: 14px;
      position: relative;
      @include respond-to(large) {
        height: 150px;
      }
      @include respond-to(medium-large) {
        height: 130px;
      }
      @include respond-to(extra-small) {
        height: 110px;
      }
    }
    .media {
      .title {
        font-size: $font-subhead;
        font-weight: bold;
        line-height: $line-height-headline;
        margin-bottom: 8px;
        width: 178px;
        text-align: center;
        @include respond-to(extra-small) {
          width: 130px;
        }
      }
    }
  }
}

.article--warmup {
  background: rgb(242, 243, 245);
  height: auto;
  display: flex;
  justify-content: center;
  .warmup--wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: $max-width;
    padding: $padding-large;
    @include respond-to(medium) {
      flex-wrap: wrap;
      align-items: center;
    }
    @include respond-to(small) {
      padding: $padding-large 0;
      &:before {
        width: 0;
      }
    }
    @include respond-to(extra-small){
      padding: $padding-medium 0;
    }
    .text__title {
      margin-bottom: 28px;
    }
    .text__content {
      margin-bottom: 40px;
    }
    .action--more {
      display: flex;
      justify-content: flex-end;
    }
  }
  .article--warmup--image {
    background: url('#{$images-static}ietaupi_img.png') no-repeat center;
    width: 100%;
    height: 350px;
    background-size: contain;
    max-width: 50%;
    padding: $padding-tiny;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include respond-to(small) {
      max-width: 100%;
      height: 224px;
    }
  }
  .article--warmup--split {
    flex: 1 1 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text__title {
      text-align: left;
    }
    @include respond-to(small) {
      order: 2;
      text-align: left;
      padding: $padding-mobile $padding-mobile 0;
      flex: 1 1 100%;
      .text__title {
        text-align: left;
      }
      .action--more {
        justify-content: flex-start;
      }
    }
    @include respond-to(small) {
      .text__title{
        text-align: center;
      }
    }
  }
}

.article--sensor {
  background: $blue;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  .sensor--wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: $max-width;
    padding: $padding-large;
    .text__title {
      margin-bottom: 28px;
    }
    .text__content {
      margin-bottom: 40px;
      @include respond-to(small) {
        margin-bottom: 0;
      }
      @include respond-to(extra-small) {
        font-size: $font-mobile-body;
        line-height: $line-height-mobile-body;
      }
    }
    .action--more {
      display: flex;
      justify-content: flex-end;
    }
    @include respond-to(small) {
      padding: $padding-small 0;
      flex-wrap: wrap;
      align-items: center;
      &:before {
        width: 0;
      }
    }
  }
  .article--sensor--image {
    background: url('#{$images-static}motion sensor.png') no-repeat center;
    width: 100%;
    height: 490px;
    max-width: 50%;
    padding: $padding-tiny;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include respond-to(medium) {
      background-size: contain;
    }
    @include respond-to(small) {
      max-width: 100%;
      height: 340px;
      margin-bottom: -70px;
    }
    @include respond-to(six-hundred) {
      height: 250px;
      margin-bottom: -35px;
    }
    @include respond-to(extra-small) {
      height: 200px;
      margin-bottom: -7px;
    }
  }
  .article--sensor--split {
    flex: 1 1 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text__title {
      text-align: left;
    }
    @include respond-to(small) {
      order: 2;
      text-align: left;
      padding: 0 $padding-mobile $padding-medium;
      .text__title {
        text-align: left;
      }
      .action--more {
        justify-content: flex-start;
      }
    }
    @include respond-to(extra-small) {
      margin-top: -20px;
      .text__title{
        text-align: center;
      }
    }
  }
}


