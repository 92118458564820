.button {
  cursor: pointer;
  outline: none;

}

.button--block {
  height: 50px;
  width: 200px;
  display: block;
  margin: 0 auto;
  background: $primary;
  line-height: 50px;
  text-align: center;
  color: white;
  border-radius: $border-radius;

  &:hover {
    background: $primary-dark;
  }
  &:active {
    background: $primary;
  }
}

.button--small {
  width: 160px;
  height: 36px;
  line-height: 36px;
  font-size: $font-small-body;
}
.button--google-play, .button--app-store {
  display: inline-block;
  width: 138px;
  height: 46px;
}
.button--google-play {
  background: url('#{$images-icons}google_play.svg') no-repeat center;
}
.button--app-store {
  background: url('#{$images-icons}app_store.svg') no-repeat center;
}

.button--inset {
  height: 40px;
  width: 160px;
  display: block;
  line-height: 34px;
  text-align: center;
  background: transparent;
  border-radius: $border-radius;
  color: $primary;
  border: 3px solid $primary;
  &.white {
    color: white;
    border-color: white;
  }
  &.white:hover {
    color: $primary;
    border-color: white;
    background: white;
  }
  &:hover {
    color: white;
    background: $primary;
  }
}


.button--subscribe {
  background: white;
  border-radius: $border-radius;
  color: $primary;
  font-size: $font-headline;
  font-weight: 500;
  height: 75px;
  line-height: 75px;
  max-width: 420px;
  text-align: center;
  width: 100%;
  user-select: none;
  &:hover {
    box-shadow: 1px 2px 12px 1px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
    color: $primary-dark;
  }
  &:active {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(0);
  }

}

.action__play {
  height: 50px;
  user-select: none;
  @include respond-to(large) {
    display: flex;
    justify-content: center;
  }
  a {
    color: $text-dark;
    font-weight: 500;
    display: block;
    text-decoration: none;
    background: rgba(255, 255, 255, 0.6 );
    line-height: 50px;
    text-align: center;
    position: relative;
    width: 186px;
    border-radius: 50px;
    padding-left: 44px;
    font-size: $font-small-body;
    box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    transform: translateY(0);
    &:hover {
      transform: translateY(-1px);
      box-shadow: 2px 0 12px 0 rgba(0, 0, 0, 0.3);
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      background: white;
      border-radius: 50px;
    }
    &:after {
      content: url('#{$images-icons}play.svg');
      width: 9px;
      height: 11px;
      position: absolute;
      top: 0;
      left: 22px;
      color: #FFD002;
    }
  }
}