@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

html {
  box-sizing: border-box;
  background: white;
  font-family: $font-stack;
  color: $text-dark;
  font-size: $font-small-body;
  font-weight: 400;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0 auto;
}
a {
  text-decoration: none;
  outline: none;
  display: block;
  color: $text-dark;
  font-size: $font-body;
  font-weight: 500;
  text-transform: uppercase;
}

