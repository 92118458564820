.features__wrap {
  width: 100%;
  max-width: $max-width;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: $padding-large 0;
  @include respond-to(extra-small){
    padding: $padding-medium 0;
  }
  .features__heading{
    padding: 0 $padding-tiny;
  }
}

.features__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $padding-extra-small;
  @include respond-to(medium) {
    padding: 0;
  }
}

.features__row {
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-wrap: wrap;
  justify-content: center;
}

.feature {
  flex: 1 0 490px;
  max-width: 50%;
  margin: 14px 0;
  display: flex;
  align-items: center;
  @include respond-to(medium) {
    max-width: 75%;
    min-width: 66%;
  }
  @include respond-to(six-hundred) {
    width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
  }
  @include respond-to(extra-small) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    margin: 14px;
    position: relative;
    @include respond-to(extra-small) {
      margin-bottom: 0;
    }
    a {
      width: 100%;
      height: 100%;

    }
    svg {
      .str0 {stroke:#65C344;stroke-width:2.3622}
      .fil0 {fill:#65C344}
      .fil1 {fill:white}
      #mobile-app {
        cursor: pointer;
      }

      #mobile-app:hover .fil0 {
        fill-opacity: 0;
      }
      #mobile-app:hover .str0 {
        stroke: #65C344;
      }

      #mobile-app:hover .fil1 {
        transition: all .4s ease-in-out;
        fill: #65C344;
      }

      #mobile-app:hover .circle {
        stroke-dasharray: 365;
        stroke-dashoffset: 365;
        animation: dash .4s ease-in-out forwards;
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }



      .diystr0 {stroke:#F7A21D;stroke-width:2.3622}
      .diystr2 {stroke:#F7A21D;stroke-width:1.49882}
      .diystr1 {stroke:white;stroke-width:0.9}
      .diyfil2 {fill:white;}
      .diyfil0 {fill:#F7A21D}
      .diyfil1 {fill:white}
      #diy {
        cursor: pointer;
      }

      #diy:hover .diyfil0 {
        fill-opacity: 0;
      }
      #diy:hover .diystr0, #diy:hover .diystr2 {
        stroke: #F7A21D;
      }

      #diy:hover .diyfil1 {
        transition: all .4s ease-in-out;
        fill: #F7A21D;
      }

      #diy:hover .diyfil2 {
        fill: #F7A21D;
        stroke-opacity: 1;
        stroke: white;
        transition: all .4s ease-in-out;
      }

      #diy:hover .diystr1 {
        stroke-width:1.5;
        stroke: #F7A21D;
        transition: all .4s ease-in-out;
      }
      #diy:hover .bottom-circle {
        fill: white;
        stroke-opacity: 0;
        fill-opacity: 1;
      }

      #diy:hover .circle {
        stroke-dasharray: 365;
        stroke-dashoffset: 365;
        animation: dash .4s ease-in-out forwards;
      }


      .secstr0 {stroke:#1C8ADB;stroke-width:2.3622}
      .secstr1 {stroke:white;stroke-width:2.3622}
      .secfil0 {fill:#1C8ADB}
      .secfil1 {fill:white}
      #security {
        cursor: pointer;
      }

      #security:hover .secfil0 {
        fill-opacity: 0;
      }
      #security:hover .secstr0 {
        stroke: #1C8ADB;
      }

      #security:hover .secfil1 {
        transition: all .4s ease-in-out;
        fill: #1C8ADB;
      }

      #security:hover .secstr1 {
        stroke: #1C8ADB;
      }

      #security:hover .circle {
        stroke-dasharray: 365;
        stroke-dashoffset: 365;
        animation: dash .4s ease-in-out forwards;
      }



      .calstr0 {stroke:#F8CC01;stroke-width:2.3622}
      .calstr1 {stroke:#F8CC01;stroke-width:0.9}
      .calfil0 {fill:#F8CC01}
      .calfil1 {fill:white}
      #calendar {
        cursor: pointer;
      }

      #calendar:hover .calfil0 {
        fill-opacity: 0;
      }
      #calendar:hover .calstr0 {
        stroke: #F8CC01;
      }
      #calendar:hover .calfil1 {
        transition: all .4s ease-in-out;
        fill: #F8CC01;
      }
      #calendar:hover .calstr1 {
        stroke-width:1.5;
        stroke: white;
      }
      #calendar:hover .window {
        fill: white;
        stroke: white;
        fill-opacity: 1;
      }

      #calendar:hover .circle {
        stroke-dasharray: 365;
        stroke-dashoffset: 365;
        animation: dash .4s ease-in-out forwards;
      }
    }
  }
  .avatar-1 {
    background: url('#{$images-icons}remote.png') no-repeat center;
  }
  .avatar-2 {
    background: url('#{$images-icons}DIY.png') no-repeat center;
  }
  .avatar-3 {
    background: url('#{$images-icons}security.png') no-repeat center;
  }
  .avatar-4 {
    background: url('#{$images-icons}schedule.png') no-repeat center;
  }
  .media {
    flex: 1 1 70%;
    padding: $padding-tiny;
    @include respond-to(extra-small) {
      min-width: 100%;
    }
    .title {
      font-size: $font-headline;
      font-weight: bold;
      line-height: $line-height-headline;
      margin-bottom: 8px;
      @include respond-to(extra-small){
        text-align: center;
      }
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: $line-height-small-body;
      @include respond-to(extra-small){
        text-align: justify;
      }
    }
  }
}
