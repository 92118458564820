.promo__container {
  background: $text-superlight;
  display: flex;
  justify-content: center;
  padding: $padding-large $padding-extra-small;
  @include respond-to(extra-small){
    padding: $padding-medium $padding-tiny;
  }
  .content--center{
    width: 100%;
    max-width: 760px;
    .text__title {
      margin-bottom: $padding-small;
      font-weight: bold;
    }
    .text__subtitle{
      margin-bottom: 0;
      @include respond-to(extra-small){
        font-size: $font-mobile-body;
        line-height: $line-height-mobile-body;
        text-align: justify;
      }
    }
  }
  .text__subtitle{
    @include respond-to(extra-small){
      font-size: $font-mobile-body;
      line-height: $line-height-mobile-body;
      text-align: justify;
    }
  }
  .form-group{
    &.has-error {
      label {
        color: $yellow;
      }
      &:after{
        background: $yellow;
      }
    }
    .error-block{
      color: $yellow;
    }
  }
}

.promo__title {
  height: 490px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('#{$images-static}p-header.png') no-repeat;
  background-position: 55% 0;
  padding: $padding-giant $padding-tiny;
  @include respond-to(extra-small) {
    padding: $padding-medium $padding-tiny;
    //background-position: 70% 0;
    background-size: cover;
  }
  color: $text-light;
  .text__title{
    font-weight: lighter;
    padding-bottom: $padding-extra-small;
  }
  .text__subtitle{
    margin: 0;
  }
}

.promo__smart {
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: $padding-tiny;
    @include respond-to(small) {
      justify-content: center;
    }
  }

  .list-column{
    min-width: 264px;
  }

  .item {
    display: flex;
    align-items: center;
    padding-top: $padding-tiny;
    @include respond-to(extra-small) {
      width: 100%;
      padding: $padding-mobile 0;
    }
    span {
      color: $primary;
    }
    div {
      width: 250px;
      text-align: left;
      padding-left: $padding-tiny;
      @include respond-to(extra-small) {
        width: 200px;
        font-size: $font-mobile-body;
        line-height: $line-height-mobile-body;
        flex-basis: 75%;
      }
    }
  ;
  }
}

.promo__save {
  background: $green;
  background: url('#{$images-static}leaf_p.png');
  color: white;
}

.promo__remote {
  height: 310px;
  background: $default;
  @include respond-to(small){
    height: auto;
    .action--platform {
      display: flex;
      justify-content: center;
    }
  }
  .wrap {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
    @include respond-to(small) {
      flex-direction: column;
      height: auto;
    }
    .image {
      background: url('#{$images-static}phone_app.png') no-repeat;
      background-position-x: 52%;
      width: 50%;
      left: 0;
      height: 310px;
      display: block;
      @include respond-to(small) {
        width: 100%;
        height: 200px;
        flex: 1 0 200px;
        position: relative;
        background-position: top 9% left 52%;
      }
    }
    .body {
      flex: 2;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      justify-content: center;
      margin: 0 $padding-large;
      height: 100%;
      text-align: left;
      min-width: 320px;
      @include respond-to(small) {
        width: 100%;
        flex: 1 1 auto;
        min-height: 267px;
        max-width: 800px;
        min-width: 0;
        margin: 0;
        padding: $padding-normal;
      }
      @include respond-to(extra-small){
        padding: $padding-medium $padding-mobile;
        margin: 0;
      }
      .text__title {
        margin-bottom: $padding-extra-small;
        text-align: left;
        @include respond-to(small){
          text-align: center;
        }
      }
      .text__subtitle {
        text-align: left;
        @include respond-to(extra-small){
          text-align: justify;
        }
      }
    }
  }
}

.promo__easy {
  background: $text-superlight;
  min-height: 310px;
  .wrap{
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    flex-direction: row-reverse;
    margin: 0 auto;
    @include respond-to(small) {
      flex-direction: column;
      height: auto;
    }
    .image {
      background: url('#{$images-static}set_up.png') no-repeat;
      background-position-x: 40%;
      width: 50%;
      min-height: 310px;
      right: 0;
      @include respond-to(small) {
        width: 100%;
        height: 310px;
        min-height: 0;
        position: relative;
        background-position: top 25% left 40%;
      }
    }
    .body {
      max-width: 500px;
      min-width: 320px;
      text-align: right;


      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 $padding-large;
      @include respond-to(small){
        flex: 1 1 auto;
        min-height: 190px;
        margin: 0;
        width: 100%;
        min-width: 0;
        max-width: 800px;
        padding: $padding-normal $padding-extra-small;
      }
      @include respond-to(extra-small){
        padding: $padding-medium $padding-mobile;
        margin: 0;
      }
      .text__title {
        text-align: right;
        margin-bottom: $padding-extra-small;
        @include respond-to(small){
          text-align: center;
        }
      }
      .text__body {
        font-size: $font-body;
        line-height: $line-height-body;
        @include respond-to(small){
          text-align: center;
        }
        @include respond-to(extra-small){
          text-align: justify;
          font-size: $font-mobile-body;
          line-height: $line-height-mobile-body;
        }
      }
    }
  }
}

.promo__schedules {
  background: white;
  .content--center{
    width: 100%;
    max-width: 960px;
  }
  .list-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .list {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: $padding-tiny;
    @include respond-to(small){
      justify-content: center;
    }
  }
  .card {
    background: $dirty-white;
    margin: 8px;
    width: 180px;
    border-radius: $border-radius;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
    margin-top: $padding-tiny;
    .title{
      font-size: 32px;
      line-height: 40px;
      padding-top: $padding-tiny;
    }
    .pic-1 {
      background: url('#{$images-icons}comfort.svg') no-repeat center;
    }
    .pic-2 {
      background: url('#{$images-icons}night.svg') no-repeat center;
    }
    .pic-3 {
      background: url('#{$images-icons}eco.svg') no-repeat center;
    }
    .pic {
      width: 100%;
      height: 80px;
      background-size: contain;
      margin: 14px 0;
      position: relative;
    }
    .temp{
      font-size: 48px;
      line-height: 56px;
      font-weight: bold;
      span{
        font-size: 48px*0.5;
      }
    }
  }
}

.promo__gift {
  height: 310px;
  background: $green;
  background: url('#{$images-static}gift_p.png');
  color: white;
  @include respond-to(small){
    height: auto;
  }
  .wrap {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
    @include respond-to(small) {
      flex-direction: column;
      height: auto;
    }
    .image {
      background: url('#{$images-static}motion_gift.png') no-repeat center;
      background-position-x: 100%;
      background-position-y: 85%;
      background-size: 290px;
      width: 48%;
      left: 0;
      height: 310px;
      display: block;
      @include respond-to(small) {
        width: 100%;
        height: 278px;
        flex: 1 0 278px;
        position: absolute;
        background-position: top 0 left 50%;
      }
    }
    .body {
      flex: 2;
      display: flex;
      flex-direction: column;
      max-width: 500px;
      justify-content: center;
      margin-right: $padding-large;
      height: 100%;
      text-align: left;
      min-width: 280px;
      @include respond-to(small) {
        width: 100%;
        flex: 1 1 auto;
        min-height: 218px;
        max-width: 800px;
        min-width: 0;
        margin: 0;
        margin-top: 200px;
        padding: $padding-normal;
      }
      @include respond-to(extra-small){
        padding: $padding-medium $padding-mobile;
      }
      .text__title {
        margin-bottom: $padding-extra-small;
        text-align: left;
        @include respond-to(small){
          text-align: center;
        }
      }
      .text__subtitle {
        text-align: left;
        @include respond-to(extra-small){
          text-align: justify;
        }
      }
    }
  }
}

.promo__sets {
  background: white;
  .content--center{
    max-width: $max-width;
  }
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: $padding-tiny;
    font-size: $font-subhead;
    line-height: $line-height-subhead;
    text-align: center;
    @include respond-to(small){
      justify-content: center;
    }
  }
  .set-card{
    flex: 1 1 260px;
    max-width: 260px;
    margin: 8px;
    border-radius: $border-radius;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-flow: column;
    margin-top: $padding-tiny;
    @include respond-to(medium){
      flex: 1 1 34%;
    }
    @include respond-to(six-hundred) {
      flex: 1 1 100%;
      flex-direction: column;
      padding: 0;
      margin: 14px;
    }
    @include respond-to(extra-small){
      max-width: 300px;
      margin: 14px 0;
    }
    .title {
      color: white;
      text-transform: uppercase;
      padding: $padding-tiny;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      //width: 300px;
      //height: 214px;
      width: 100%;
      height: 240px;
      @include respond-to(six-hundred) {
        width: 100%;
      }
      &.promo_basic {
        background: url('#{$images-sets-promo}gas.png')  center bottom no-repeat;
        background-size: contain;
      }
      &.promo_one {
        background: url('#{$images-sets-promo}1_room.png')  center bottom no-repeat;
        background-size: contain;
      }
      &.promo_two {
        background: url('#{$images-sets-promo}2_room.png')  center bottom no-repeat;
        background-size: contain;
      }
      &.promo_three {
        background: url('#{$images-sets-promo}3_room.png')  center bottom no-repeat;
        background-size: contain;
      }
    }
    .content {
      background: $lightest-grey;
      padding: $padding-small;
    }
  }
  .learn-more{
    margin-top: 14px;
    width: 100%;
    max-width: 130px;
    font-size: $font-small-body;
    line-height: 28px;
    height: 28px;
    background: none;
    border: 1px solid;
  }
  .terms {
    font-size:$font-mobile-body;
    line-height: $line-height-mobile-body;
    text-align: left;
    padding-bottom: $padding-tiny;
    font-weight: lighter;
  }
}

.promo__container {

  .promo_basic {
    &.set-card, &.button--buy, .text__title {
      background: #92C4EA;
    }
    .learn-more, .price {
      color: #92C4EA;
    }
  }
  .promo_one {
    &.set-card, &.button--buy, .text__title {
      background: #B5D668;
    }
    .learn-more, .price {
      color: #B5D668;
    }
  }
  .promo_two {
    &.set-card, &.button--buy, .text__title {
      background: #FFCE4D;
    }
    .learn-more, .price {
      color: #FFCE4D;
    }
  }
  .promo_three {
    &.set-card, &.button--buy, .text__title {
      background: #F58552;
    }
    .learn-more, .price {
      color: #F58552;
    }
  }
  .button--buy{
    width: 100%;
    height: 36px;
    line-height: 36px;
  }

  .option {
    text-align: left;
    //padding-left: 30px;
  }

  .bold {
    font-weight: bold;
  }
  .price{
    font-size: 28px;
  }
  .price--large{
    font-size: 50px;
  }

  .rent, .full {
    //margin-left: -30px;
    line-height: 24px;
    .radio {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0px 5px 0 0;
      //vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 $grey;
      background: white;
      .checked {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: -6px 22px 0px 5px;
        vertical-align: middle;
        border-radius: 50%;
        &.active {
          background-color: $grey;
        }
      }
    }
  }
  .full{
    padding-bottom: $padding-tiny;
  }

  input[type="radio"], input[type="checkbox"] {
    display: none;

    & + label .radio {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 3px 22px 0 0;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 $grey;
    }

    & + label {
      & .radio {
        position: absolute;
        margin-left: -30px;
      }
    }

    & + label .radio {
      background-color: white;
    }

    & + label .radio .checked {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: -10px 22px 0px 5px;
      vertical-align: middle;
      border-radius: 50%;
    }

    &:checked + label .radio .checked {
      background-color: $grey;
    }

    & + label .radio,
    &:checked + label .radio {
      transition: background-color 0.3s linear;
    }
  }


}

.promo__reviews {
  background: $default;

  .review {
    text-align: center;
    font-style: italic;
    font-size: $font-body;
    line-height: $line-height-body;
    font-weight: lighter;
  }
  .pic-1 {
    background: url('#{$images-static}atsauksme_21.png') no-repeat center;
  }
  .pic-2 {
    background: url('#{$images-static}atsauksme_22.png') no-repeat center;
  }
  .pic-3 {
    background: url('#{$images-static}janis.png') no-repeat center;
  }
  .pic {
    height: 100px;
    background-size: contain;
    margin: 14px;
    position: relative;
  }

}

.promo__contact {
  background: $green;
  background: url('#{$images-static}leaf_p.png');
  color: white;
  text-align: center;
  font-weight: lighter;
  padding: $padding-medium $padding-tiny;
  .address{
    font-size: $font-headline;
    line-height: $line-height-headline;
  }
  .other {
    font-size: $font-mobile-body;
    line-height: $line-height-mobile-body;
    padding: $padding-tiny 0;
  }
  .footer__social{
    height: 40px;
    align-items: center;
    justify-content: center;
  }
}

.promo__footer {
  padding: $padding-tiny $padding-extra-small $padding-tiny $padding-extra-small;
}

.promo_set-heading {
  height: 300px;
  color: white;
  display: flex;
  justify-content: flex-end;
  &.promo_basic {
    background: url('#{$images-static}boiler_header.png') no-repeat;
    background-position: right 67% top 0;
    @include respond-to(medium-large){
      background-position: right -700px top 0;
    }
    @include respond-to(small){
      background-position: right -400px top 0;
    }
  }
  &.promo_one {
    background: url('#{$images-static}1_room_header.png') no-repeat;
    background-position: right 67% top 0;
    @include respond-to(medium-large){
      background-position: right -600px top 0;
    }
    @include respond-to(medium){
      background-position: right -100px top 0;
    }
    @include respond-to(small){
      background-position: right -350px top 0;
    }
  }
  &.promo_two {
    background: url('#{$images-static}2_room_header.png') no-repeat;
    background-position-x: 50%;
    @include respond-to(large){
      background-position-x: -350px;
    }
    @include respond-to(medium-large){
      background-position: right -600px top 0;
    }
    @include respond-to(medium){
      background-position: right 0 top 0;
    }
    @include respond-to(small){
      background-position: right -250px top 0;
    }
  }
  &.promo_three {
    background: url('#{$images-static}3_room_header.png') no-repeat;
    background-position-x: 50%;
    @include respond-to(large){
      background-position-x: -350px;
    }
    @include respond-to(medium-large){
      background-position: right -600px top 0;
    }
    @include respond-to(medium){
      background-position: right 0 top 0;
    }
    @include respond-to(small){
      background-position: right -250px top 0;
    }
  }
  .heading-container{
    display: flex;
    flex-basis: 50%;
    flex-wrap: wrap;
    align-content: center;
    @include respond-to(medium-large){
      flex-basis: 440px;
    }
  }
  .title, .subtitle {
    padding: $padding-tiny*0.5 $padding-tiny;
  }
  .title {
    font-weight: lighter;
  }
  .subtitle{
    width: 100%;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
    text-transform: uppercase;
  }
}

.promo-specification {
  background: $lightest-grey;

  &,.text__title, .text__content, .specification__animation{
    color: $text-grey;
  }

  svg{
    height: 60px;
    padding-bottom: $padding-tiny;
  }

  .set__devices {
    .fill {
      fill: $dark-grey;
    }
    .active {
      background: none;
      color: $green;
      .fill {
        fill: $green;
      }
    }
    .set__device{
      width: 240px;
    }
  }

  .device__slider{
    //width: 100%;
    //overflow: hidden;
    //position: relative;
    height: 380px;
    @include respond-to(small) {
      height: 710px;
    }
    @include respond-to(six-hundred) {
      height: 784px;
    }
    @include respond-to(extra-small){
      height: 736px;
    }

  }

  .full-width{
    width: 100%;
  }

  .description__content{
    flex-direction: row-reverse;
    @include respond-to(small) {
      justify-content: flex-end;
    }
    .description__text {
      max-width: 600px;
      align-content: center;
    }
    .description__text, .description__text .text__title{
      text-align: right;
      @include respond-to(small) {
        text-align: left;
      }
    }
  }
  .pic {
    width: 50%;
    height: 380px;
    right: 0;
    @include respond-to(medium) {
      width: 48%;
    }
    @include respond-to(small) {
      width: 100%;
      height: 310px;
      min-height: 0;
      position: relative;
      background-position: top 25% left 40%;
    }
  }
  .pic-base_station {
    background: url('#{$images-static}base.png') no-repeat;
    background-position-x: 19%;
    @include respond-to(extra-small){
      background-size: cover;
    }
  }
  .pic-temperature_sensor {
    background: url('#{$images-static}temperature.png') no-repeat;
    background-position-x: 19%;
    @include respond-to(small){
      background-position-y: 55%;
    }
    @include respond-to(extra-small){
      background-position-x: 24%;
    }
  }
  .pic-wireless_regulator {
    background: url('#{$images-static}regulator.png') no-repeat;
    background-position-x: 40% !important;
    @include respond-to(extra-small){
      background-position-x: 44% !important;
    }
  }
  .pic-boiler_switch {
    background: url('#{$images-static}boiler_switch.png') no-repeat;
    background-position-x: 17%;
    @include respond-to(small){
      background-position-y: 55%;
    }
    @include respond-to(extra-small){
      background-position-x: 15%;
    }
  }

  .wrap {
    max-width: $max-width;
    margin: 0 auto;
    padding: $padding-large 0;
    @include respond-to(extra-small){
      padding: $padding-medium 0;
    }
  }

  .specification__slider{
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 430px;

    @include respond-to(medium-large){
      height: 600px;
    }
    @include respond-to(small) {
      height: 676px;
    }
    @include respond-to(six-hundred) {
      height: 1122px;
    }
    @include respond-to(extra-small){
      height: 1114px;
    }
  }

  .wireless{
    order:1;
  }
  .power{
    order:2;
  }
  .size{
    order:3;
  }
  .operating{
    order:4;
  }
  .internet{
    order: 5;
  }

  .warranty{
    order:6;
  }
}

.promo_home_config {
  color: $text-grey;
  .wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .text__title{
    width: 100%;
    padding-bottom: $padding-normal;
  }
  .home-config-container {
    display: flex;
    justify-content: center;
    width: 100%;
    @include respond-to(small){
      flex-wrap: wrap;
    }
    .option {
      //text-align: left;
      padding-left: 30px;
    }
  }
  .sliders {
    flex-basis: 360px;
    .title {
      font-size: $font-headline;
      line-height: $line-height-headline;
      font-weight: bold;
    }
    .mini{
      color: $text-grey-light;
    }
  }
  .content {
    flex-basis: 360px;
    font-size: $font-subhead;
    line-height: $line-height-subhead;
    padding: 0 $padding-small $padding-small $padding-large;
    @include respond-to(small){
      padding:$padding-small; //ipadam
    }
    @include respond-to(six-hundred){
      padding:$padding-small 0;
    }
    @include respond-to(extra-small){
      //flex-basis: 300px;
    }
    .title {
      font-size: $font-headline;
      line-height: $line-height-headline;
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: $padding-tiny;
    }
    .items{
      font-size: $font-small-body;
      line-height: $line-height-small-body;
      padding-bottom: 20px;
      .item{
        transition: 0.5s linear;
        &.ng-animate{
          color: $primary;
        }
      }
    }
  }
  .button {
    margin-top: $padding-tiny;
  }

  .slider-container {
    height: 46px;
    margin: $padding-medium 0 0 0;
  }


  count-slider, [count-slider] {
    width: 100%;
    height: 60px;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
  }

  .knob {
    position: absolute;
    display: block;
    height: 67px;
    width: 63px;
    top: -37px;
    left: -22px;
    z-index: 10;
    cursor: pointer;
  }

  .rail {
    user-select: none;
    width: 100%;
    height: 5px;
    position: relative;
    background: $lighter-grey;

    .tooltip {
      position: absolute;
      color: $text-light;
      font-size: 18px;
      font-weight: bold;
      width: 80px;
      border-radius: 3px;
      text-align: center;
      top: -64px !important;
      padding: 8px 12px;
      @include respond-to(extra-small){
        font-size: 16px;
        width: 59px;
        padding: 15px 0;
      }
      background: $primary;
      .tooltip-arrow {
        position: absolute;
        bottom: -7px;
        left: 42%;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $primary;
        @include respond-to(extra-small){
          left:38%;
        }
      }
    }

    .progress {
      user-select: none;
      background: $primary;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      max-width: 0;
    }

    .marker-container{
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      height: 20px;
      top: -8px;
    }

    .marker {
      cursor: pointer;
      user-select: none;
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 16px;
      background: white;
      border: 3px solid $lighter-grey;
      top: -8px;
      z-index: 5;
      &:after {
        content: '';
        transition: all .4s ease-in-out;
        opacity: 0;
        transform: scale(2, 2);
      }
      &.active {
        background: $primary;
        border-color: $primary;
        &:not(.v-0) {
          &:after {
            content: '';
            background-image: url('#{$images-icons}house2.svg');
            height: 19px;
            width: 18px;
            position: relative;
            display: block;
            top: -30px;
            left: -2px;
            opacity: 1;
            transform: scale(1, 1);
          }
        }
      }
    }
  }

  .scale {
    height: 40px;
    user-select: none;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    color: $lighter-grey;
    padding: 0;
    .scale--value{
      width: 20px;
      height: 16px;
      padding: 0;
    }
    .active:not(.v-0) {
      color: $dark-grey;
    }
  }
}

.promo__order {
  color: $text-grey;
  background: white;
  padding: $padding-large $padding-tiny;
  @include respond-to(extra-small){
    padding-top: $padding-mobile;
    padding-bottom: $padding-medium;
  }
  .wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .text__title, .order-details{
    width: 100%;
  }

  .person_type {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    padding-top: $padding-tiny;
    @include respond-to(extra-small){
      flex-wrap: wrap;
    }
    .button{
      text-transform: none;
      height: 30px;
      line-height: 30px;
      &.not_selected {
        background: none;
        color: $text-grey;
      }
    }
  }

  .order-details{
    display: flex;
    justify-content: center;
    @include respond-to(small){
      flex-wrap: wrap;
    }
  }

  .order-details--container{
    flex:0 0 400px;
    padding: $padding-normal;
    @include respond-to(medium){
      flex: 0 0 375px;
    }
    @include respond-to(extra-small){
      flex: 0 0 100%;
      padding: $padding-normal 0;
    }
  }
  .title{
    color: $primary;
    font-weight: bold;
    font-size: $font-body;
    line-height: $line-height-body;
  }
  .title--set-up {
    padding-top: $padding-normal;
  }

  .option {
    padding-left: $padding-medium;
    width: 100%;
    border-radius: $border-radius;
    background: $dirty-white;
    margin-top: $padding-tiny;
    label {
      height: 60px;
      display: flex;
      align-items: center;
    }
    input[type="radio"] + label .radio,
    input[type="checkbox"] + label .radio{
      position: relative;
      margin: auto auto auto -30px;
      flex: 0 0 20px;
      min-width: 0;
      .checked{
        margin-top: 5px;
      }
    }

    input[type="checkbox"] {
      & + label span {
        border-radius: 15%;
        margin: -1px 15px 0 0;
      }
      & + label span .checked{
        border-radius: 15%;
        margin: 5px 15px 0 5px;
      }

      & + label {
        & span {
          position: absolute;
          margin-left: -30px;
        }
      }
    }
    .fa {
      width: 60px;
      //text-align: left;
    }
    .text{
      //padding-left: 18px;
      flex-grow: 1;
    }
    .text--price{
      font-weight: bold;
      font-size: 18px;
      flex-grow: 1;
      text-align: right;
      padding: $padding-tiny;
      @include respond-to(extra-small){
        padding: $padding-tiny $padding-tiny*0.5;
      }
    }
    .sub-text{
      font-size: $font-tiny;
      br{
        display: none;
      }
    }
  }

  .error {
    background-color: $red;
    position: relative;
    color: $text-light;
    max-width: 420px;
    margin: $padding-tiny auto;
    padding: $padding-tiny;
    border-radius: $border-radius;

    .text {
      text-align: left;
      margin-right: 30px;
    }

    .alert {
      position: absolute;
      right: 25px;
      top: 35%;
      font-size: 28px;
      opacity: 0.6;
    }
  }

  .order-end {
    text-align: center;
    div {
      font-size: $font-body;
      padding-bottom: $padding-tiny;
    }
    span{
      font-weight: bold;
    }
    select{
      font: inherit;
      color: inherit;
      border: none;
      box-shadow: 0 0 2px 0 $grey;
    }
    .total{
      color: $primary;
      font-weight: bold;
    }
    .button{
      max-width: 220px;
      &.disabled {
        cursor: not-allowed;
        background: $lighter-grey;
      }
    }

    .option--terms {
      background: none;
      margin-top: 0;
      padding-left: 0;
      @include respond-to(extra-small){
        width: 260px;
        margin: 0 auto;
        padding-left: 40px;
      }
      div {
        padding-bottom: 0;
        padding-left: $padding-tiny;
      }
      label{
        height: 18px;
        @include respond-to(extra-small){
          height: 36px;
        }
      }

      input[type="checkbox"] {
        & + label span {
          border-radius: 15%;
          margin: -1px 15px 0 0;
        }
        & + label span .checked{
          border-radius: 15%;
          margin: 5px 15px 0 5px;
        }

        & + label {
          & span {
            //position: absolute;
            margin-right: -15px;
            margin-left: 0;
          }
        }
      }

      a{
        display: inline;
        text-transform: inherit;
        font-weight: inherit;
        color: #e27474;
      }
    }

  }

}

.promo__order--success{
  .content--center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .text__title {
      margin: 0;
    }
  }
  .fa{
    color: $primary;
    font-size: 7em;
  }
  .text__title{
    font-size: 32px;
    padding: $padding-tiny 0;
    margin: 0;
  }
  .text__subtitle{
    font-size: 22px;
  }
}