
/**
* COLOR DEFINITIONS
*/
$black : #31343B;
$dark-grey: #676A6F;
$grey: #7F8289;
$light-grey: #A8ABB2;
$lighter-grey: #CDD0D5;
$lightest-grey: #E5E6EA;
$dirty-white: #F3F3F4;

$background-green: #EFF8E7;
$dark-green: #6DAA28;
$green: #80C036;
$light-green: #80C036;

$yellow: #FCCC00;

$red: #FB5859;

$blue: #2DB4F4;
$dark-blue: #3ab3c6;
$darkest-blue: #314a60;

/**
* ACTUAL COLORS
*/

$primary: $green;
$primary-dark: $dark-green;
$primary-light: $light-green;

$text-dark: $black;
$text-grey: $dark-grey;
$text-grey-light: $grey;
$text-light: white;
$text-superlight: #F2F3F5;

$default: $lightest-grey;

/**
* FONT STACK/SIZES
*/

$font-stack: 'Roboto', sans-serif;

$font-tiny: 12px;
$line-height-tiny: 20px;

$font-small-body: 14px;
$line-height-small-body: 24px;

$font-mobile-body: 16px;
$line-height-mobile-body: 24px;

$font-body: 18px;
$line-height-body: 28px;

$font-subhead: 18px;
$line-height-subhead: 28px;

$font-headline: 24px;
$line-height-headline: 32px;

$font-display: 40px;
$line-height-display: 48px;



/**
* PADDINGS
*/

$padding-tiny: 14px;
$padding-extra-small: 22px;
$padding-small: 28px;
$padding-normal: 32px;
$padding-medium: 40px;
$padding-large: 80px;
$padding-giant: 120px;

$padding-mobile: 14px;


$max-width: 1250px;

/**
* BORDER RADIUS
*/
$border-radius: 3px;

$border-radius-large: 6px;

$border-color: $lighter-grey;


/**
* Image locations
*/

$images-custom: '../images/custom/';
$images-devices-large: '../images/devices/large/';
$images-devices-small: '../images/devices/small/';
$images-heating: '../images/heating/';
$images-icons: '../images/icons/';
$images-sets-promo: '../images/sets/promo/';
$images-specs: '../images/specs/';
$images-static: '../images/static/';
$images-slider: '../images/slider/';
$images-solutions: '../images/solutions/';



