body.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  background: rgba(0, 0, 0, .5);
}

#contact-form {
  .content {
    flex: 1 auto;
    border-radius: $border-radius;
    box-shadow: 0 2px 1px rgba(115, 118, 116, 0.5);
    position: fixed;
    top: 5%;
    left: 50%;
    margin-left: -200px;
    z-index: 1041;
    background: #FFFFFF;
    width: 400px;
    overflow-y: auto;
    height: 100%;
    max-height: 666px;
    @include respond-to(extra-small){
      width: 100%;
      margin-left: 0;
      bottom: 0;
      top: 0;
      left: 0;
      max-height: none;
    }

    .logo {
      height: 100px;
      text-align: center;
      background: url('#{$images-icons}/istabai.png') no-repeat center;
      background-color: $lightest-grey;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      @media(max-height: 564px){
        display: none;
      }
    }

    .close {
      position: absolute;
      right: 10px;
      top: 7px;
      cursor: pointer;
      opacity: 0.8;
      font-size: 22px;
      color: $lighter-grey;

      &:hover {
        opacity: 1;
        color: $light-grey;
      }
    }

    .body {
      padding: 12px;
      text-align: center;
      a {
        display: inline-block;
        text-decoration: none;
        color: $primary;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
      }

      .title {
        font-size: 22px;
        font-weight: bold;
        padding: 18px 0;
      }

      .subtitle {
        margin-bottom: 14px;
        font-size: 16px;
        color: $lighter-grey;
        @include respond-to(extra-small){
          margin-bottom: 2px;
        }
      }

      .form {
        height: 100%;
        padding: 0 $padding-extra-small $padding-extra-small $padding-extra-small;
      }

      .send {
        padding: $padding-tiny;
        background-color: $primary;
        color: #FFFFFF !important;
        text-align: center;
        border-radius: $border-radius;
        width: 100%;
        @include respond-to(extra-small){
          padding: 9px;
        }
        &.disabled {
          cursor: not-allowed;
          background: $lighter-grey;
        }
      }
      .formSaved{
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
      }


      .formError {
        padding: $padding-tiny;
        border-radius: $border-radius;
        background-color: $red;
        position: relative;
        color: $text-light;
        top:-10px;

        .text {
          text-align: left;
          margin-right: 30px;
        }

        .alert {
          position: absolute;
          right: 25px;
          top: 20%;
          font-size: 28px;
          opacity: 0.6;
        }
      }
    }
  }
  input {
    @include respond-to(extra-small) {
      padding: 12px 0 12px;
    }
  }
}