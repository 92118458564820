@font-face {
  font-family: 'FontAwesome';
  src: url('../../vendor/font-awesome-4.4.0/fonts/fontawesome-webfont.eot');
  font-weight: normal;
  font-style: normal;
}


.support__faq {
  display: flex;
  justify-content: center;

  .faq__wrap {
    box-sizing: content-box !important;
    display: flex;
    flex-direction: row;
    @include respond-to(small) {
      flex-direction: column;
    }
    margin: $padding-extra-small 14px;
    max-width: $max-width;
    padding-bottom: $padding-extra-small;
    align-items: flex-start;
    width: 100%;
  }

  .faq-item {
    padding-bottom: $padding-extra-small;
    iframe {
      width:640px;
      height:360px;
      @include respond-to(medium) {
        width:100%;
      }
      @include respond-to(extra-small) {
        height:180px;
      }
    }
  }

  .tabs-links {
    & li {
      padding-top: $padding-extra-small;

      @include respond-to(small) {
        padding: 11px 0;
        @include respond-to(six-hundred) {
          padding: 0;
          &.toggled {
            padding: 11px 0;
          }
          &.active {
            padding: 11px 0;
          }
        }
      }
    }

    & a {
      text-transform: capitalize;
      display: flex;
      justify-content: flex-start;
      color: $text-grey-light;

      &.active {
        color: $primary;

        @include respond-to(six-hundred) {
          &:after {
            font-family: FontAwesome;
            content: "\f0d7";
            padding-left: 10px;

          }
          &.toggled:after {
            font-family: FontAwesome;
            content: "\f0d8";
          }
        }
      }

      @include respond-to(six-hundred) {
        &:not(.active) {
          display: none;
        }

        &.toggled {
          display: block;
        }
      }

      &:hover {
        color: $primary-dark;
        cursor: pointer;
      }
    }
  }

  .tabs {
    &.menu {
      width: 20%;

      .pinned {
        width: 18.5%;
      }

      @include respond-to(small) {
        width: 100%;
        min-height: 50px;
      }
        .tabs-links {
          &.pinned {
            position: fixed;
            top: 56px;
          }
          @include respond-to(small) {
            background: #FFFFFF;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            @include respond-to(six-hundred) {
              flex-direction: column;
              align-items: center;
            }
            &.pinned {
              position: fixed;
              top: 0;
              width: 100%;
              left: 0;
            }
            &.shadow {
              box-shadow: 1px 2px 5px grey;
            }
          }
        }

      font-size: $font-subhead;
      line-height: $line-height-subhead;
    }

    &.content {
      width: 80%;
      margin-left: 20px;
      @include respond-to(small) {
        margin-left: 0;
        width: 100%;
      }
      padding: $padding-extra-small 0;
      font-size: $font-subhead;
    }
  }


  .question {
    font-weight: bold;
    font-size: $font-headline;
    line-height: $line-height-headline;
    padding-bottom: 10px;
  }

  .answers {
    line-height: $line-height-body;

    sup {
      // Test
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
      top: -0.5em;
      z-index: -1;
    }

    a {
      text-transform: none;
      display: inline-block;
      color: $primary;
      &:hover {
        color: $primary-dark;
      }
    }
  }

}