.text--white {
  color: white;
}
.text__title {
  line-height: $line-height-display;
  font-size: $font-display;
  font-weight: bold;
  text-align: center;
}
.text__subtitle {
  line-height: $line-height-subhead;
  font-size: $font-subhead;
  margin-bottom: $padding-small;
  text-align: center;
}

.text__content {
  line-height: $line-height-body;
  font-size: $font-body;
}

.text__email {
  justify-content: center;
  display: flex;
  margin-bottom: 8px;
  input {
    background: transparent;
    border: 2px solid white;
    border-radius: $border-radius;
    color: white;
    display: block;
    font-size: 16px;
    height: 48px;
    max-width: 420px;
    text-align: center;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &::-webkit-input-placeholder {
      color: #FFFFFF;
    }

    &:-moz-placeholder {
      color: #FFFFFF;
    }

    &::-moz-placeholder {
      color: #FFFFFF;
    }

    &:-ms-input-placeholder {
      color: #FFFFFF;
    }


  }
}