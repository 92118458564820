.article--feedback {
  display: flex;
  justify-content: center;
  background: $text-superlight;
}

.feedback__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  padding: $padding-giant $padding-extra-small;
}
.feedback__heading {
  margin-bottom: 60px;
}
.feedback__block {
  display: flex;
  @include respond-to(medium) {
    flex-direction: column;
    align-items: center;
  }
}
.feedback {
  background: white;
  margin: 8px;
  border-radius: $border-radius;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  @include respond-to(extra-small) {
    flex-direction: column;
    align-items: center;
  }
  .thumbnail {
    background: $light-grey;
    height: 200px;
    width: 150px;
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    @include respond-to(extra-small) {
      width: 100%;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
    }
  }
  .body {
    padding: $padding-tiny;
    flex: 1 1 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    @include respond-to(extra-small) {
      min-height: 200px;
      justify-content: flex-start;
    }
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
  }
  .company {
    color: $text-grey-light;
    margin-bottom: $padding-extra-small;
    font-size: $font-tiny;
  }
  .text {
    font-size: 16px;
    line-height: 22px;
  }
}
