
.about__hero {
  background: url('#{$images-static}about-header.jpg') no-repeat center;
  padding: $padding-giant $padding-tiny;
  @include respond-to(extra-small) {
    padding: $padding-medium $padding-tiny;
  }
  color: white;
  .text__title {
    margin-bottom: $padding-extra-small;
  }
}
.about__container {
  display: flex;
  justify-content: center;
}
.about__vision {
  height: 550px;
  background: white;
  @include respond-to(six-hundred){
    height: 500px;
  }
  .vision--wrap {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    @include respond-to(six-hundred) {
      flex-direction: column;
      height: auto;
    }
    .vision__image {
      background: url('#{$images-static}about-history.jpg') no-repeat;
      background-position: center;
      width: 40%;
      left: 0;
      height: 550px;
      display: block;
      @include respond-to(six-hundred) {
        width: 100%;
        height: 200px;
        position: relative;
        background-position: top 40% left 70%;
      }
    }
    .vision__body {
      height: 100%;
      text-align: left;
      @include respond-to(six-hundred) {
        margin-left: 0;
      }
      .text__title {
        text-align: left;
        @include respond-to(extra-small){
          text-align: center;
        }
      }
    }
  }
}
.about__history {
  background: $text-superlight;
  min-height: 550px;
  .history--wrap{
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    flex-direction: row-reverse;
    @include respond-to(six-hundred) {
      flex-direction: column;
      height: auto;
    }
    .history__image {
      background: url('#{$images-static}about-mission.jpg') no-repeat;
      background-position: center;
      width: 40%;
      min-height: 550px;
      right: 0;
      @include respond-to(six-hundred) {
        width: 100%;
        height: 200px;
        min-height: 0;
        position: relative;
        background-position: top 40% left 70%;
      }
    }
    .history__body {
      text-align: right;
      @include respond-to(six-hundred){
        margin-right: 0;
      }
      .text__title {
        text-align: right;
        @include respond-to(extra-small){
          text-align: center;
        }
      }
    }
  }
}
.body__container {
  flex: 2;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  justify-content: center;
  margin: 0 80px;
  @include respond-to(small){
    margin: 0 50px;
  }
  @include respond-to(six-hundred){
    padding: $padding-normal;
  }
  @include respond-to(extra-small){
    padding: $padding-medium $padding-mobile;
    margin: 0;
  }
  .text__title {
    margin-bottom: $padding-extra-small;
  }
}
.text__body {
   font-size: $font-body;
   line-height: $line-height-body;
   @include respond-to(extra-small){
     text-align: justify;
     font-size: $font-mobile-body;
     line-height: $line-height-mobile-body;
   }
 }