body.modal-open {
  overflow: hidden;
}

.modal__close {
  position: absolute;
  right: 5px;
  color: #FFFFFF;
  padding: 10px;
  font-size: 34px;
  font-weight: 400;
  opacity: 0.3;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  background: rgba(0, 0, 0, .5);
}

#modal-play-video {
  z-index: 101;
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;

  background: #fff;
  box-shadow: 1px 2px 5px rgba(0,0,0,.5);

  @include respond-to(small) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
}

.modal--terms {
  padding: $padding-normal;
  @include respond-to(extra-small){
    padding: $padding-tiny 0;
  }
  .modal__close {
    top: 0;
    color: $dark-grey;
  }


  .menu__logo {
    background: url('#{$images-icons}/istabai.png') no-repeat left;
    background-size: 197px 50px;
    display: block;
    height: 50px;
    margin-left: 14px;
    width: 250px;
    @include respond-to(extra-small){
      background-size: 99px 25px;
      height: 25px;
    }
  }
  .terms-container{
    width: 100%;
    height: calc(100% - 50px - 32px);
    padding: $padding-tiny;
    max-width: $max-width;
    overflow-y: scroll;
    font-size: $font-small-body;
    line-height: $line-height-small-body;
    text-align: justify;
    .terms-title, .terms-subtitle{
      font-weight: bold;
      padding: $padding-tiny 0;
    }
    .no-bold {
      font-weight: normal;
    }
  }
  .download{
    padding: $padding-tiny;
    margin: 0 auto;
    font-size: $font-headline;
    line-height: $line-height-headline;
    text-align: center;
    text-transform: none;
  }
}