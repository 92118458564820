.sets__wrap {
  display: flex;
  flex-direction: column;
  max-width: $max-width;
  width: 100%;
  .sets__heading{
    padding: $padding-tiny;
    .text__subtitle{
      padding-top: $padding-tiny;
      margin: 0;
    }
    .sets{
      margin: 0;
    }
  }
}