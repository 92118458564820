.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $default;
  align-items: center;
  padding: $padding-large $padding-extra-small 5px $padding-extra-small;
  @media(max-width: 2020px) {
    padding-bottom: $padding-normal;
  }
  @include respond-to(extra-small){
    padding-left: $padding-mobile;
    padding-right: $padding-mobile;
    padding-top: $padding-medium;
  }
}

.footer__inner {
  max-width: $max-width;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  justify-content: space-between;
  @include respond-to(medium-large) {
    justify-content: space-around;
  }

  @include respond-to(small) {
    flex-direction: column;
    align-items: center;
    max-width: 500px;
  }

  .showLarge {
    @include respond-to(small){
      display: none;
    }
  }
}

.footer__item {
  display: flex;
  flex-direction: row;
  margin: 8px;
  @include respond-to(small) {
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }

  .footer__title {
    vertical-align: middle;
    font-size: $font-headline;
    font-weight: 600;
    line-height: $line-height-headline;
  }

  .footer__heading {
    margin-top: 5%;
    white-space: nowrap;
    font-size: $font-subhead;
    line-height: $line-height-subhead;
    font-weight: bold;
  }

  .footer__subheading {
    font-weight: lighter;
    font-size: $font-small-body;
    height: 40px;
    vertical-align: text-top;
  }

  .footer__body {
    height: 20%;
    font-size: $font-body;
    line-height: $line-height-body;
  }
}

.footer__media--ceo {
  background: url('#{$images-static}contact-us-lindermanis.jpg') no-repeat center;
  width: 200px;
  height: 254px;
  border-radius: $border-radius;
  @include respond-to(small) {
    flex-basis: 42%;
    order: 2;
  }
}

.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 20px;
  height: 254px;
  @include respond-to(small) {
    flex-basis: 58%;
    min-width: 149px;
    margin-left: 0;
    order: 1;
  }
}
.footer__social {
  height: 25%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .footer__social__item {
    width: 48px;
    height: 40px;
    cursor: pointer;
    &:hover {
      opacity: .75;
    }
  }

  .footer__social__item--draugiem {
    background: url('#{$images-icons}128-draugiem.png') no-repeat left;
    background-size: 40px 40px;
  }

  .footer__social__item--facebook {
    background: url('#{$images-icons}128-facebook.png') no-repeat left;
    background-size: 40px 40px;
  }

  .footer__social__item--twitter {
    background: url('#{$images-icons}128-twitter.png') no-repeat left;
    background-size: 40px 40px;
  }

  .footer__social__item--linkedin {
    background: url('#{$images-icons}128-linkedin.png') no-repeat left;
    background-size: 40px 40px;
  }
}

.footer__item--address {
  flex-direction: column;
  justify-content: flex-end;
  @include respond-to(six-hundred) {
    align-items: flex-start;
  }

  .footer__contact{
    @include respond-to(small) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: stretch;
      margin: 20px 0;
      .footer__address {
        flex-basis: 58%;
        min-width: 149px;
        margin-right: 20px;
        .footer__heading{
          margin: 0;
        }
      }
    }

    .showSmall {
      display: none;
      @include respond-to(small){
        flex-direction: column;
        display: flex;
        height: 100%;
        padding: 0;
        align-items: center;
        margin: 0;
        flex-basis: 42%;
        width: auto;
        max-width: none;
        .letter{
          margin: 14px 0 0 0;
        }
        .text{
          padding: 9px;
          width: 100%;
          @include respond-to(extra-small){
            font-size: $font-small-body;
            line-height: $line-height-small-body;
            width: 100%;
          }
        }
      }
    }
  }

  .footer__newsletter__heading {
    @include respond-to(small) {
      text-align: center;
      width: 100%;
    }
  }

  .footer__subheading--primary{
    @include respond-to(medium-large){
      width: 190px;
    }
    @include respond-to(small) {
      text-align: center;
      width: 100%;
    }
  }
}

.footer__subheading--primary {
  color: $primary;
}

.footer__newsletter {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  @include respond-to(small) {
    width: 100%;
    max-width: 300px;
    margin-top: 10px;
    align-self: center;
  }
  .textbox {
    outline: none;
    background-color: $default;
    border: 2px solid $primary;
    border-radius: $border-radius;
    color: $primary;
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
    &::-webkit-input-placeholder {
      color: $primary;
    }
    &:-moz-placeholder {
      color: $primary;
    }
    &::-moz-placeholder {
      color: $primary;
    }
    &:-ms-input-placeholder {
      color: $primary;
    }
  }
  .subscribe, .subscribe__success {
    outline: none;
    padding: 5px;
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: $border-radius;
    text-transform: uppercase;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
  }
  .subscribe {
    cursor: pointer;
  }
  .subscribe:hover {
    background: $primary-dark;
  }
  .subscribe:active {
    background: $primary-light;
  }
  .subscribe__success {
    line-height: 52px;
  }
  .subscribe__error{
    padding-bottom: $padding-tiny;
    color: $red;
    text-align: center;
  }
}

.footer__item--write-to-us {
  @include respond-to(small) {
    display: flex;
    justify-content: center;
    padding: $padding-small;
  }
}

.footer__write-to-us {
  border-radius: $border-radius;
  background: $primary;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  min-height: 106px;
  cursor: pointer;
  @include respond-to(six-hundred) {
    min-height: 0;
    height: 200px;
  }
  &:hover {
    background: $primary-dark;
  }
  &:active {
    background: $primary-light;
  }
  .letter {
    background: url('#{$images-icons}mail.svg') no-repeat center;
    background-size: 100%;
    display: block;
    width: 48px;
    height: 37px;
    margin-bottom: 20px;
  }
  .text{
    font-size: $font-body;
    line-height: $line-height-body;
    text-align: center;
  }
}

.footer__inner--copyrightlogo {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @include respond-to(medium-large) {
    justify-content: space-around;
  }
  @include respond-to(six-hundred) {
    flex-direction: column;
  }

  .footer__logo--copyright {
    width: 389px;
    color: $text-grey-light;
    font-size: $font-subhead;
    padding: $padding-extra-small 0;
    margin-left: 8px;
    @include respond-to(small) {
      width: auto;
    }
    @include respond-to(six-hundred) {
      font-size: 16px;
      width: initial;
      text-align: center;
    }
    @include respond-to(extra-small){
      width: 100%;
      margin-left: 0;
    }
  }

  .footer__logo {
    background: url('#{$images-icons}istabai-mini.png') no-repeat center;
    background-size: 138px 35px;
    width: 239px;
    height: 35px;
    padding-top: 60px;
    @include respond-to(six-hundred) {
      width: 182px;
    }
  }

  .footer__logo--draugiem {
    background: url('#{$images-icons}draugiem-grupa.png') no-repeat center;
    background-size: 190px 36px;
    width: 215px;
    height: 36px;
    padding-top: 60px;
    @include respond-to(six-hundred) {
      width: 190px;
    }
  }
}