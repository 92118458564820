.image--mobile {
  background: url('#{$images-static}istabai-home-header.png') no-repeat center;
  background-size: 329px 498px;
  position: absolute;
  width: 329px;
  height: 498px;
  left: 50%;
  top: 18%;
  margin-left: -229px;

  @include respond-to(large) {
    top: 45%;
    left: 28%;
    height: 498px * .75;
    width: 329px * .75;
    background-size: 329px * .75 498px * .75;
    margin-left: 0;
  }
  @include respond-to(small) {
    left: 19%;
    top: 53%;
    height: 498px * .6;
    width: 329px * .6;
    background-size: 329px * .6 498px * .6;
  }
  @include respond-to(extra-small) {
    height: 498px * 0.4;
    width: 329px * 0.4;
    left: 21%;
    top: 58%;
    background-size: 329px * 0.4 498px * 0.4;
  }
}

.image--comfort {
  background: url('#{$images-static}comfort.jpg') no-repeat;
  background-size: 1013px 745px;
  background-position: right -136px top -60px;
  height: 620px;
  width: 42%;
  top: 0;
  left: 0;
  position: absolute;
  @include respond-to(extra-small) {
    position: relative;
    height: 160px;
    width: 100%;
    background-position:top -120px right -15px;
    background-size: 506px 372px;
  }
}

.image--apps {
  background: url('#{$images-static}istabai-home-header.png') no-repeat center;
  background-size: 271px 410px;
  height: 410px;
  width: 409px;
  padding-right: 80px;
  @include respond-to(medium) {
    width: 50%;
    background-position: center;
  }
  @include respond-to(small) {
    height: 498px * 0.4;
    width: 329px * 0.4;
    padding-right: 0;
    background-size: 329px * 0.4 498px * 0.4;
  }
  @include respond-to(six-hundred) {
    height: 498px * 0.3;
    width: 329px * 0.3;
    background-size: 329px * 0.3 498px * 0.3;
  }
}
