.header {
  background: $default;
  border-bottom: 1px solid rgba($border-color, 0.5);
  font-size: $font-small-body;
  height: 35px;
  line-height: 35px;
}
.header--inner {
  display: flex;
  margin: 0 auto;
  max-width: $max-width;
}
.header__quote {
  color: $text-grey-light;
  flex: 1 1 0;
  overflow: hidden;
  padding-left: $padding-tiny;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include respond-to(medium) {
    visibility: hidden;
  }
}
.header__tel {
  color: $text-grey-light;
  width: 238px;
  @include respond-to(extra-small) {
    visibility: hidden;
    width: 0;
  }
}
.header__login {
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  text-align: center;
  width: 100px;
  height: 35px;
  @include respond-to(extra-small) {
    width: 92px;
  }

  a {
    color: $primary;
    font-weight: 500;
    text-decoration: none;
    font-size: $font-small-body;
    position: relative;
    outline: none;
    &:hover {
      color: $primary-dark;
    }
    &:before {
      content: url('#{$images-icons}lock.svg');
      position: relative;
      left: -5px;
      bottom: -1px;
    }
  }
}
.dropdown {
  background: $default;
  border: 0 solid $border-color;
  border-width: 0 0 1px 1px;
  cursor: pointer;
  display: none;
  left: 0;
  position: absolute;
  top: 35px;
  width: inherit;

}
.dropdown__item {
  border: 0 solid $border-color;
  border-width: 1px 1px 0 0;
  border-collapse: collapse;
  &:hover {
    background: white;
    cursor: pointer;
  }
}
.dropdown__selected {
  cursor: pointer;
  display: block;
  width: 49px;
  &.LV {
    background: url('#{$images-icons}lv.gif') no-repeat left;
    //background-size: contain;
  }
  &.EN {
    background: url('#{$images-icons}gb.gif') no-repeat left;
    //background-size: contain;
  }
  a {
    text-align: right;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-top: 3px solid transparent;
    border-top: 3px solid $text-dark;
  }
}
.header__language {
  border-left: 1px solid $border-color;
  color: $text-dark;
  font-weight: 500;
  position: relative;
  text-align: center;
  width: 100px;
  height: 35px;
  @include respond-to(extra-small) {
    width: 92px;
  }
  &:hover {
    .dropdown {
      display: block;
    }
  }
}

