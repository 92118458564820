.shop__display {
  background: $primary;
  color: white;
  .wrap {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: $padding-large $padding-mobile;
    @include respond-to(small) {
      flex-direction: column-reverse;
    }
    @include respond-to(extra-small){
      padding: $padding-medium $padding-mobile;
    }
  }
  .picture {
    flex-basis: 50%;
    display: block;
    width: 100%;
    @include respond-to(small) {
      flex: 1 0 auto;
    }
    img {
      display: block;
      margin: auto auto auto 10%;
      @include respond-to(medium) {
        margin-left: auto;
      }
      @include respond-to(extra-small) {
        width: 100%;
        max-width: 344px;
        height: auto;
      }
    }
  }
}

.shop__heading {
  flex-basis: 50%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include respond-to(small) {
    width: 100%;
    flex: 0 1 auto;
    margin-top: 40px;
    text-align: center;
    align-items: center;
  }
  .title {
    font-size: $font-display;
    font-weight: bold;
    line-height: $line-height-display;
    width: 100%;
  }
  .text {
    font-size: $font-body;
    line-height: $line-height-body;
    width: 100%;
  }
  .call-to-action {
    background: white;
    color: $primary;
    margin: 0;
    margin-top: $padding-extra-small;
    &:hover {
      color: $primary-dark;
    }
  }
}

.shop__display--promo {
  min-height: 350px;
  display: flex;
  align-items: center;
  background: url('#{$images-static}shop_header_promo.png') no-repeat;
  background-position-x: 50%;
  background-size: cover;
  @include respond-to(small){
    background-position-x: 48%;
  }
  @include respond-to(extra-small){
    //height: 400px;
    background-position-x: 33%;
  }
  &.non-lv{
    //background: url('#{$images-static}shop_header_promo.png') no-repeat;
    //color: $text-grey;
  }
  .wrap{
    height: 100%;
    width: 100%;
    padding: $padding-medium $padding-tiny;
    flex-direction: row;
  }
  .left-space{
    flex:0 1 16%;
    @include respond-to(medium){
      flex:0 1 9%;
    }
    @include respond-to(extra-small){
      flex:0 1 0;
    }
  }
  .shop__heading{
    text-align: left;
    flex:0 0 550px;
    align-items: flex-start;
    margin: 0;
    @include respond-to(small){
      flex:0 0 62%;
    }
    @include respond-to(extra-small){
      flex:1 0 100%;
      .title{
        font-size: 39px;
      }
    }
    .title, .text{
      font-weight: lighter;
    }
    .text {
      display: flex;
      align-items: flex-end;
      //div {
      //  height: 100%;
      //}
      .big{
        font-size: 50px;
        line-height: 50px;
        font-weight: bold;
        span{
          font-size: 23px;
          line-height: 12px;
        }
      }
      .last{
        margin-left: 4px;
      }
      .super {
        font-weight: bold;
        height: 20px;
        font-size: 23px;
        margin-left: -1px;
      }
    }
    .button{
      margin: 0;
      margin-top: $padding-extra-small;
    }
  }
}

.shop__products {
  background: $text-superlight;
  padding: $padding-large 0;
  @include respond-to(extra-small){
    padding: $padding-medium 0;
  }
  .wrap {
    max-width: $max-width;
    margin: 0 auto;
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $padding-extra-small;
    .text__content {
      width: 100%;
      text-align: center;
    }
    @include respond-to(extra-small) {
      padding: 0 $padding-mobile;
    }
    .text__title {
      margin-bottom: $padding-extra-small;
      width: 100%;
    }
  }
}

#product {

}

.sets {
  display: flex;
  width: 100%;
  margin: 40px 0;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 8px;
}

#set {
  flex: 1 1 34%;
  max-width: 600px;
  background: white;
  margin: 8px;
  padding: $padding-extra-small;
  border-radius: $border-radius;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  @include respond-to(large){
    flex: 1 1 100%;
  }
  @include respond-to(six-hundred) {
    flex-direction: column;
    padding: 0;
    margin: 14px;
  }
  @include respond-to(extra-small){
    margin: 14px 0;
  }
  .picture {
    display: flex;
    width: 256px;
    height: 256px;
    @include respond-to(six-hundred) {
      width: 100%;
    }
    img {
      display: block;
      margin: auto;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $padding-extra-small;
    flex-basis: 50%;
    @include respond-to(six-hundred){
      flex: 1 0 auto;
    }
    @include respond-to(extra-small){
      padding: $padding-extra-small $padding-mobile;
    }
  }
  .title {
    margin-bottom: 14px;
    @include respond-to(extra-small){
      text-align: center;
    }
    a {
      color: $primary;
      font-size: 22px;
      line-height: 1.5;
      &:hover {
        color: $primary-dark;
        font-weight: bold;
      }
    }
  }
  .text {
    color: $text-grey-light;
    line-height: 1.5;
    @include respond-to(extra-small){
      text-align: justify;
    }
  }
  .bottom-block {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .explore-block {

  }

  .explore {
    width: 110px;
  }

}

.price-block {
  font-size: $font-body;
  line-height: $line-height-body;
}
.sub-text {
  color: $text-grey-light;
  font-size: $font-tiny;
  line-height: $line-height-tiny;
}

.individual-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

#product {
  background: white;
  margin: 8px;
  width: 294px;
  border-radius: $border-radius;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  &.air_quality_sensor {
    order: 1;
  }
  .picture {
    width: 100%;
    height: 244px;
    img {
      display: block;
      margin: auto;
      height: 100%;
      width: auto;
    }

  }

  .body {
    flex: 1 1 0;
    padding: $padding-extra-small;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    a {
      text-align: center;
      color: $primary;
      font-weight: 500;
      font-size: $font-body;
      line-height: 1.5;
      &:hover {
        color: $text-dark;
      }
    }
    margin-bottom: 6px;
  }
  .text {
    text-align: center;
    color: $text-grey-light;
    line-height: 1.5;
    height: 63px;
  }
  .bottom-block {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    font-size: $font-body;
    line-height: $line-height-body;
  }
  .add-to-cart {
    margin: 0;
    width: 140px;
    height: 38px;
    line-height: 38px;
  }
  .preOrder {
    display:none;
  }


  &.air_quality_sensor {

    a{
      .preOrder {
        display: block;
        background-color: $primary;
        height: 38px;
        color: white;
        line-height: 38px;
        font-size: $font-body;
        font-weight: bold;
        text-align: center;
        border-radius: $border-radius $border-radius 0 0;

      }
    }

    .add-to-cart{
      display: none;
      &.preOrder{
        display: block;
        &.LV{
          width: 180px;
        }
      }
    }

    .picture{
        height: 206px;
    }

  }


}
