.main-menu {
  height:94px;
  position: fixed;
  top: 0;
  background: white;
  z-index: 100;
  width: 100%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

  @include respond-to(extra-small) {
    height: 56px;
  }
  @media (max-width: 250px) {
    position: absolute;
  }
}

.menu--inner {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $max-width;
  align-items: center;
  height: 100%;

  .menu__logo {
    background: url('#{$images-icons}/istabai.png') no-repeat left;
    background-size: 197px 50px;
    display: block;
    height: 100%;
    margin-left: 14px;
    width: 250px;
    transition: all 0.2s ease;
    @include respond-to(extra-small){
      background-size: 99px 25px;
    }
  }

  .menu__block {
    display: flex;
    align-items: center;

    .menu__item {
      color: $text-dark;
      display: block;
      font-weight: 500;
      font-size: $font-body;
      padding: 0 18px;
      outline: none;
      text-align: center;
      text-decoration: none;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      -webkit-font-smoothing: antialiased;
      svg{
        height: 21px;
        vertical-align: middle;
      }

      @include respond-to(medium-large) {
        &:not(.menu__item--cart):not(.menu__stacked){
          display: none;
        }
      }
    }

    .menu__item--active {
      color: $primary;
    }

    .menu__item--cart {
      position: relative;
      width: 65px;

      .menu__cart__badge {
        font-size: $font-tiny;
        line-height: $line-height-tiny;
        right: 18px;
        position: absolute;
        top: -10px;
      }
    }

    .menu__item--has-items {
      color: $primary;
      .cls-2 {
        fill: $primary;
      }
    }

    .menu__stacked {
      height:21px;
      display: none;
      color: $text-dark;
      padding: 0 40px;
      @include respond-to(medium-large) {
        display: block;
        background: url('#{$images-icons}hamburger.svg') no-repeat center;
      }

      @include respond-to(extra-small) {
        padding: 0 20px;
        background-size: contain;
      }
    }
  }
  .menu__block--right{
    width: 250px;
    justify-content: space-between;
    @include respond-to(medium-large) {
      justify-content: flex-end;
    }

    .menu__item--language {
      height: 100%;
      padding: 0;
      &:hover {
        .dropdown {
          display: block;
          top: -7px;
          background-color: $text-superlight;

          .dropdown__item {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.menu__item--login {
  text-align: center;
  width: 100px;
  white-space: nowrap;
  @include respond-to(extra-small) {
    width: 92px;
  }

  a {
    color: $primary;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    outline: none;
    &:hover {
      color: $primary-dark;
    }
    &:before {
      content: " ";
      background-image: url('#{$images-icons}lock.svg');
      background-size: cover;
      position: relative;
      display: inline-block;
      right: 5px;
      width: 11px;
      height: 15px;
      /*      content: url('#{$images-icons}lock.svg');
            position: relative;
            left: -5px;
            bottom: -1px;*/
    }
  }
}

.side-menu {
  padding: $padding-extra-small;

  .side-menu__item {
    padding: 8px 0;
  }

  .side-menu__item--login{
    width: 100%;
    text-align: left;
    padding-left: 7px;
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }

  .side-menu__item--languages {
    .fa-caret-down{
      margin-right: 5px;
      margin-left: 3px;
    }

    .side-menu__item--options{
      padding: 8px 23px;
    }

    .dropdown__selected{
      margin-top: 8px;
      width: 45px;
      padding-left: 23px;
      a{
        text-align: left;
      }
    }
  }

  .side-menu__item--active {
    color: $primary;
  }
}

.navbar--space{
  height: 94px;
  @include respond-to(extra-small) {
    height: 56px;
  }}

nav.navbar {
  transition: all 0.2s ease;

  background-color: white;
  margin-bottom: 0;
}

.navbar-fixed-top {
  padding: 0;
  height: 56px;
  .menu__logo {
    background-size: 99px 25px;
  }
  .menu__item{
    font-size: $font-small-body;
    .dropdown__selected{
      width: 39px;
    }
    a{
      font-size: $font-small-body;
      line-height: 15px;
      &:before {
        right: 2px;
        width: 8px;
        height: 11px;
      }
    }
  }
  .menu__stacked {
    background-size: contain;
  }
  @include respond-to(extra-small) {
    .menu__item {
      line-height: $line-height-tiny;
    }
  }

  .menu__item--language {
    &:hover {
      .dropdown {
        top: -1px;
      }
    }
  }
}
