.support__team {
  background: $text-superlight;
  .support__heading {
    padding: $padding-medium $padding-tiny;
    .text__subtitle {
      margin: 0;
    }
  }

  .support__blocks {
    display: flex;
    justify-content: center;
    @include respond-to(medium) {
      flex-direction: column;
      align-items: center;
      padding: $padding-mobile;
    }
    @include respond-to(extra-small){
      padding: 0 8px;
    }
    .support__item {
      max-width: 400px;
      width: 100%;
      background: white;
      margin: 8px;
      border-radius: $border-radius;
      box-shadow: 1px 0 3px 0 rgba(0,0,0,0.3);
      display: flex;
      flex-direction: column;
      .body {
        padding: $padding-extra-small;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include respond-to(large) {
          padding: $padding-tiny;
        }
      }
      @include respond-to(medium){
        max-width: 800px;
        flex-direction: row;
        .media, .body {
          flex-basis: 50%;
        }
      }
      @include respond-to(small){
        max-width: 400px;
        flex-direction: column;
        .media, .body {
          flex-basis: auto;
        }
      }
      .title {
        font-size: $padding-extra-small;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 14px;
      }
      .text {
        font-size: $font-body;
        line-height: $line-height-body;
        margin-bottom: 14px;
        height: 56px;
      }
      .action {
        display: flex;
        justify-content: flex-start;
        height: 46px;
        @include respond-to(extra-small) {
          flex-direction: column;
          height: auto;
        }

        .learn-more {
          line-height: 46px;
          color: $primary;
          &:hover {
            color: $primary-dark;
          }
        }
        .setup-istabai {
          line-height: 46px;
          margin: 0 $padding-extra-small 0 0;
          height: 46px;
          width: 180px;
        }
        .button--google-play {
          margin-right: 8px;
          @include respond-to(extra-small) {
            margin-bottom: 8px;
          }
        }

      }
      &.how-it-works{
        .media {
          background: url('#{$images-static}how-it-works.png') no-repeat center;
        }
        .body{
          .title{
            text-transform: none;
          }
          .text{
            text-transform: none;
            font-weight: normal;
          }
        }
      }
      &.setup {
        .media {
          background: url('#{$images-static}simple-setup.jpg') no-repeat;
        }
      }
      &.mobile {
        .media {
          background: url('#{$images-static}app.jpg') no-repeat;
          height: 197px;
          @include respond-to(extra-small) {
            background-position: center;
          }
        }
        @include respond-to(extra-small){
          .text{
            height: auto;
          }
        }
      }


      .video{
        height: 197px;
        @include respond-to(extra-small) {
          background-position: center;
        }
        a{width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.5;
          color:$light-grey;
          font-size: 60px;
          &:hover{
            color:$lightest-grey;
            background-color: rgba(0, 0, 0, 0.6);
          }
        }
      }

    }
  }

  .faq-header {
    max-width: $max-width;
    margin: 40px auto;
    padding: $padding-tiny $padding-tiny 50px;
    .text__title {
      text-align: left;
      @include respond-to(extra-small){
        text-align: center;
      }
    }
  }
}

