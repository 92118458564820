/**
* Mixins goes here
*/

@mixin respond-to($breakpoint) {
  @if $breakpoint == extra-small {
    @media(max-width: 451px) { @content; }
  }
  @if $breakpoint == six-hundred {
    @media(max-width: 600px) { @content; }
  }
  @if $breakpoint == small {
    @media(max-width: 769px) { @content; }
  }
  @if $breakpoint == medium {
    @media(max-width: 992px) { @content; }
  }
  @if $breakpoint == medium-large {
    @media(max-width: 1050px) { @content; }
  }
  @if $breakpoint == large {
    @media(max-width: 1200px) { @content; }
  }

}