.form-group {
  position: relative;
  width: 100%;
  margin-bottom: $padding-extra-small;

  input[type='text'], input[type='email'], textarea[type='text'] {
    position: relative;
    width: 100%;
    background: 0 0;
    padding: 26px 0 12px;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, .08);
    border-width: 0 0 1px;
    box-shadow: none;
    z-index: 1;
    &:focus {
      outline: none;
    }
  }

  label {
    display: inline-block;
    position: absolute;
    margin-bottom: 6px;
    font-size: $font-tiny;
    font-weight: 300;
    color: rgba(0,0,0,0.4);
    padding: 0;
    transition: all .3s ease;
  }


  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    z-index: 1000;
    background: rgba(0,0,0,.08);
    transition: all .3s ease;
  }

  .error-block {
    position: absolute;
    font-weight: 300;
    font-size: $font-tiny;
    color: red;
    line-height: $line-height-small-body;
    transition: all 0.5s ease;
    margin: 0;

    &.ng-hide {
      display: block !important;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.has-focus {
    label {
      color: $primary;
    }
    &::after {
      left: 0;
      background: $primary;
      width: 100%;
    }
  }

  &.has-error {
    label {
      color: red;
    }
    &::after {
      left: 0;
      background: red;
      width: 100%;
    }
    .help-block:not(.ng-hide) {
      position: absolute;
      color: red;
      opacity: 1;
      visibility: visible;
      top: 100%;
    }
  }



  &.has-success.has-focus, &.has-success:not(.is-empty) {
    label {
      color: $primary;
    }
    &.has-focus::after, &::after {
      left: 0;
      background: $primary;
      width: 100%;
    }
  }

  &.is-empty:not(.has-focus) label {
    font-weight: 400;
    font-size: $font-small-body;
    top: 32px;
  }


}