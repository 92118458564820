body.aside-open {
  overflow: hidden;
}

#cart {
  width: 420px;
  padding: $padding-small;
  @include respond-to(extra-small) {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  hr {
    height: 1px;
    color: $text-superlight;
    background-color: $text-superlight;
    border: none;
  }

  .close {
    text-transform: uppercase;
    text-decoration: none;
    color: $text-grey-light;
    display: block;
    padding: $padding-tiny 0;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 8px 0;

    .title {
      line-height: $line-height-display;
      color: $text-grey-light;

      &::before {
        content: url('#{$images-icons}cart.svg') center no-repeat;
        margin-right: 8px;
      }
    }
  }

  .empty {
    color: $text-grey-light;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: $padding-small 0;
  }

  .totals {
    display: flex;
    justify-content: space-between;
    padding: $padding-small 0 $padding-tiny;
  }
  .subtotal {
    .title {
      font-size: $font-body;
      line-height: $line-height-body;
    }
    .text {
      font-size: $font-tiny;
      line-height: $line-height-tiny;
      color: $text-grey-light;
    }
  }
  .price {
    font-size: $font-headline;
    color: $primary;
    line-height: $line-height-display;
  }
  .checkout {
    display: block;
    margin: 14px 0;
    color: white;
    padding: $padding-tiny 30px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: $font-small-body;
    font-weight: 600;
    background-color: $primary;
    border-radius: $border-radius;
    &:hover {
      background: $primary-dark;
      color: white;
    }
  }
  .continue {
    display: block;
    margin: 28px 0;
    text-decoration: none;
    text-transform: uppercase;
    color: $text-grey-light;
    &::before {
      content: '<';
      margin-right: 2px;
      font-size: 20px;
    }
  }



  .product {

    background-color: white;

    &.animate {
      transition: background-color 0.5s ease;
      background-color: $text-superlight;
    }

    display: flex;
    padding: $padding-tiny;
    align-items: center;
    @include respond-to(extra-small){
      padding: 0;
      margin: 0 -28px;
    }
    .remove {
      padding: 0;
      text-decoration: none;
      color: $text-grey-light;
      display: block;
      font-size: 22px;
      &:hover {
        color: $text-dark;
      }
    }
    .media {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      padding: 8px;
      @include respond-to(extra-small){
        max-width: 240px;
      }
    }
    .picture {
      width: 122px;
      height: 122px;
      @include respond-to(extra-small){
        width: 100px;
        height: 100px;
      }
      margin: 8px;
      &.base_station {
        background: url('#{$images-devices-small}base-station.png') center no-repeat;
        background-size: contain;
      }
      &.motion_sensor {
        background: url('#{$images-devices-small}motion-sensor.png') center no-repeat;
        background-size: contain;
      }
      &.temperature_sensor {
        background: url('#{$images-devices-small}temperature-sensor.png') center no-repeat;
        background-size: contain;
      }
      &.wired_regulator {
        background: url('#{$images-devices-small}wired-regulator.png') center no-repeat;
        background-size: contain;
      }
      &.wireless_regulator {
        background: url('#{$images-devices-small}wireless-regulator.png') center no-repeat;
        background-size: contain;
      }
      &.multi_switch {
        background: url('#{$images-devices-small}multi-switch.png') center no-repeat;
        background-size: contain;
      }
      &.probe_sensor {
        background: url('#{$images-devices-small}probe-sensor.png') center no-repeat;
        background-size: contain;
      }
      &.power_switch {
        background: url('#{$images-devices-small}power-switch.png') center no-repeat;
        background-size: contain;
      }
      &.boiler_switch {
        background: url('#{$images-devices-small}boiler-switch.png') center no-repeat;
        background-size: contain;
      }
      &.air_quality_sensor {
        background: url('#{$images-devices-small}air-quality-sensor.png') center no-repeat;
        background-size: contain;
      }
      &.smart_plug {
        background: url('#{$images-devices-small}smart-plug.png') center no-repeat;
        background-size: contain;
      }
    }
    .body {
      height: 28px;
      display: flex;
      justify-content: space-between;
      .name {
        line-height: $line-height-body;
      }
    }
    .quantity {
      display:flex;
      color: $text-grey-light;
      a {
        color: $text-grey-light;
        text-decoration: none;
        padding: 0 8px;
        font-size: 22px;
        &:hover {
          color: $text-dark;
        }
      }
    }
    .qty {
      color: $text-grey-light;
      transition: color 0.5s ease;
      font-size: $font-tiny;
      line-height: 26px;
      margin-right: 12px;
      white-space: nowrap;
    }
    .action {
      display: flex;
      text-align: end;
      justify-content: space-between;
      .price {
        font-size: $font-body;
        line-height: $line-height-body;
        color: $text-dark;
        white-space: nowrap;
      }
    }
  }

  a:hover {
    color: $text-dark;
  }
}
